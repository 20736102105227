import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Navigate, useSearchParams } from "react-router-dom";
import {
  addDays,
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DateTime } from "luxon";
import Skeleton from "react-loading-skeleton";
import {
  Plan,
  ProjectUsage,
  UsageType,
  useBusinessesQuery,
  useBusinessUsageQuery,
} from "../../generated/graphql";
import BusinessPage from "./BusinessPage";

import Label from "../../components/Label";
import ErrorMessageCard from "../../components/ErrorMessageCard";
import { CustomTimeRanges, TimeRange } from "../../lib/types";
import { useHypertune } from "../../generated/hypertune.react";
import Card from "../../components/Card";
import {
  intentPrimaryHex,
  intentPrimaryHex50,
  intentPrimaryHex70,
} from "../../lib/constants";
import TimeRangePicker from "../../components/TimeRangePicker";
import TopBarDropdown from "../../components/TopBarDropdown";
import useSearchParamsState from "../../app/useSearchParamsState";

const fromKey = "usage_from";
const toKey = "usage_to";

export default function UsagePage(): React.ReactElement {
  useEffect(() => {
    document.title = "Usage - Hypertune";
  }, []);

  const hypertune = useHypertune();
  const { loading, error, data } = useBusinessesQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const timeRange: TimeRange = useMemo(
    () => ({
      start: new Date(searchParams.get(fromKey) ?? startOfMonth(new Date())),
      end: new Date(searchParams.get(toKey) ?? endOfDay(new Date())),
    }),
    [searchParams]
  );
  const [timeRangeInitialized, setTimeRangeInitialized] = useState(
    !!searchParams.get(fromKey)
  );
  const setTimeRange = useCallback(
    (newTimeRange: TimeRange) => {
      setSearchParams((currentSearchParams) => ({
        ...Object.fromEntries(currentSearchParams),
        [fromKey]: newTimeRange.start.toJSON(),
        [toKey]: newTimeRange.end.toJSON(),
      }));
    },
    [setSearchParams]
  );
  const [selectedProjectIdsString, setSelectedProjectIdsString] =
    useSearchParamsState("selected_projects", "");

  const selectedProjectIds = useMemo(
    () =>
      new Set(
        selectedProjectIdsString ? selectedProjectIdsString.split(",") : []
      ),
    [selectedProjectIdsString]
  );
  const setSelectedProjectIds = useCallback(
    (newIds: Set<string>) => {
      setSelectedProjectIdsString(newIds.values().toArray().join(","));
    },
    [setSelectedProjectIdsString]
  );

  const invoices = useMemo(
    () =>
      data?.primaryBusiness?.business.plan !== Plan.Free
        ? (data?.primaryBusiness?.business.invoices ?? [])
        : [],
    [
      data?.primaryBusiness?.business.plan,
      data?.primaryBusiness?.business.invoices,
    ]
  );
  const previousBillingPeriodRange: TimeRange | null = useMemo(
    () =>
      invoices && invoices.length > 0
        ? {
            start: startOfDay(new Date(invoices[0].start)),
            end: endOfDay(new Date(invoices[0].end)),
          }
        : null,
    [invoices]
  );
  const currentBillingPeriodRange: TimeRange | null = useMemo(
    () =>
      previousBillingPeriodRange
        ? {
            start: startOfDay(addDays(previousBillingPeriodRange.end, 1)),
            end: endOfDay(new Date()),
          }
        : null,
    [previousBillingPeriodRange]
  );
  useEffect(() => {
    if (!timeRangeInitialized && currentBillingPeriodRange) {
      setTimeRange(currentBillingPeriodRange);
      setTimeRangeInitialized(true);
    }
  }, [setTimeRange, timeRangeInitialized, currentBillingPeriodRange]);

  const customDataRanges: CustomTimeRanges = useMemo(
    () => [
      ...((previousBillingPeriodRange && currentBillingPeriodRange
        ? [
            {
              label: "Current billing period",
              value: [
                currentBillingPeriodRange.start,
                currentBillingPeriodRange.end,
              ],
            },
            {
              label: "Previous billing period",
              value: [
                previousBillingPeriodRange.start,
                previousBillingPeriodRange.end,
              ],
            },
          ]
        : [
            {
              label: "Month to date",
              value: [startOfMonth(new Date()), endOfDay(new Date())],
            },
            {
              label: "Last month",
              value: [
                startOfMonth(subMonths(new Date(), 1)),
                endOfMonth(subMonths(new Date(), 1)),
              ],
            },
          ]) as CustomTimeRanges),
      {
        label: "Last 7 days",
        value: [subDays(new Date(), 6), new Date()],
      },
      {
        label: "Last 30 days",
        value: [subDays(new Date(), 29), new Date()],
      },
    ],
    [currentBillingPeriodRange, previousBillingPeriodRange]
  );

  return (
    <BusinessPage>
      {loading ? (
        <UsageView
          projects={data?.primaryBusiness?.business.projects ?? null}
          selectedProjectIds={selectedProjectIds}
          setSelectedProjectIds={setSelectedProjectIds}
          customDataRanges={customDataRanges}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          chartData={null}
        />
      ) : error ? (
        <ErrorMessageCard error={error} />
      ) : data ? (
        !hypertune.showUsagePage({ fallback: false }) ? (
          <Navigate to="/" />
        ) : !data.primaryBusiness ? (
          <Label type="small-body" className="pt-[9.5px] text-tx-muted">
            No team selected.
          </Label>
        ) : (
          <Usage
            businessId={data.primaryBusiness.id}
            projects={data.primaryBusiness.business.projects}
            selectedProjectIds={selectedProjectIds}
            setSelectedProjectIds={setSelectedProjectIds}
            customDataRanges={customDataRanges}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
          />
        )
      ) : null}
    </BusinessPage>
  );
}

function Usage({
  businessId,
  projects,
  selectedProjectIds,
  setSelectedProjectIds,
  customDataRanges,
  timeRange,
  setTimeRange,
}: {
  businessId: string;
  projects: { id: string; name: string }[];
  selectedProjectIds: Set<string>;
  setSelectedProjectIds: (newIds: Set<string>) => void;
  customDataRanges: CustomTimeRanges;
  timeRange: TimeRange;
  setTimeRange: (newTimeRange: TimeRange) => void;
}): React.ReactElement {
  const { data, loading, error } = useBusinessUsageQuery({
    variables: {
      input: {
        businessId,
        start: timeRange.start.toJSON(),
        end: timeRange.end.toJSON(),
      },
    },
  });

  if (error) {
    return <ErrorMessageCard error={error} />;
  }
  console.log("Usage data", data?.businessUsage ?? []);

  const dataByDate =
    data?.businessUsage
      .filter(
        ({ projectId }) =>
          selectedProjectIds.size === 0 || selectedProjectIds.has(projectId)
      )
      .reduce<{
        [date: string]: ProjectUsage[];
      }>((byDate, current) => {
        if (!(current.occurredOn in byDate)) {
          byDate[current.occurredOn] = [];
        }
        byDate[current.occurredOn].push(current);

        return byDate;
      }, {}) ?? {};

  const showYear =
    timeRange.start.getFullYear() !== timeRange.end.getFullYear();
  const allDays = Math.round(
    (timeRange.end.getTime() - timeRange.start.getTime()) /
      (24 * 60 * 60 * 1000)
  );

  const chartData = !loading
    ? new Array(allDays).fill(0).map((_, index) => {
        const date = addDays(timeRange.start, index);
        const dateData = dataByDate[date.toJSON()];
        const name = DateTime.fromJSDate(date).toFormat(
          showYear ? "dd LLL yyyy" : "dd LLL"
        );

        if (!dateData) {
          return {
            name,
            events: 0,
            exposures: 0,
            hash: 0,
            inits: 0,
            js: 0,
            graphQL: 0,
          };
        }

        return {
          name,
          events: sumUsageForType(dateData, UsageType.Events),
          exposures: sumUsageForType(dateData, UsageType.Exposures),
          hash: sumUsageForType(dateData, UsageType.HashRequests),
          inits: sumUsageForType(dateData, UsageType.InitRequests),
          js: sumUsageForType(dateData, UsageType.JsRequests),
          graphQL: sumUsageForType(dateData, UsageType.GraphQlRequests),
        };
      })
    : null;
  console.log("Chart data", allDays, chartData);

  const showJS = chartData?.some(({ js }) => js);
  const showGQL = chartData?.some(({ graphQL }) => graphQL);

  return (
    <>
      <div className="mb-5 flex flex-row justify-between">
        <Label type="title1">Usage</Label>
        <div className="flex flex-row items-center gap-2">
          <ProjectSelect
            projects={projects}
            selectedProjectIds={selectedProjectIds}
            setSelectedProjectIds={setSelectedProjectIds}
          />
          <div className="rounded-lg border bg-white">
            <TimeRangePicker
              range={timeRange}
              setRange={setTimeRange}
              placement="bottomEnd"
              customDateRanges={customDataRanges}
            />
          </div>
        </div>
      </div>

      <BarChartCard title="Initialization requests" data={chartData}>
        <Bar name="Inits" dataKey="inits" stackId="a" fill={intentPrimaryHex} />
        {showJS && (
          <Bar name="JS" dataKey="js" stackId="a" fill={intentPrimaryHex70} />
        )}
        {showGQL && (
          <Bar
            name="GraphQL"
            dataKey="graphQL"
            stackId="a"
            fill={showJS ? intentPrimaryHex50 : intentPrimaryHex70}
          />
        )}
      </BarChartCard>

      <BarChartCard title="Hash requests" data={chartData}>
        <Bar name="Hash" dataKey="hash" stackId="a" fill={intentPrimaryHex} />
      </BarChartCard>

      <BarChartCard title="Analytics events" data={chartData}>
        <Bar
          name="Exposures"
          dataKey="exposures"
          stackId="a"
          fill={intentPrimaryHex}
        />
        <Bar
          name="Events"
          dataKey="events"
          stackId="a"
          fill={intentPrimaryHex70}
        />
      </BarChartCard>
    </>
  );
}

function UsageView({
  projects,
  selectedProjectIds,
  setSelectedProjectIds,
  customDataRanges,
  timeRange,
  setTimeRange,
  chartData,
}: {
  projects: { id: string; name: string }[] | null;
  selectedProjectIds: Set<string>;
  setSelectedProjectIds: (newIds: Set<string>) => void;
  customDataRanges: CustomTimeRanges;
  timeRange: TimeRange;
  setTimeRange: (newTimeRange: TimeRange) => void;
  chartData:
    | {
        name: string;
        init: number;
        hash: number;
        js: number;
        graphQL: number;
      }[]
    | null;
}): React.ReactElement {
  const showJS = chartData?.some(({ js }) => js);
  const showGQL = chartData?.some(({ graphQL }) => graphQL);

  return (
    <>
      <div className="mb-5 flex flex-row justify-between">
        <Label type="title1">Usage</Label>
        <div className="flex flex-row items-center gap-2">
          <ProjectSelect
            projects={projects}
            selectedProjectIds={selectedProjectIds}
            setSelectedProjectIds={setSelectedProjectIds}
          />
          <div className="rounded-lg border bg-white">
            <TimeRangePicker
              range={timeRange}
              setRange={setTimeRange}
              placement="bottomEnd"
              customDateRanges={customDataRanges}
            />
          </div>
        </div>
      </div>

      <BarChartCard title="Initialization requests" data={chartData}>
        <Bar name="Inits" dataKey="inits" stackId="a" fill={intentPrimaryHex} />
        {showJS && (
          <Bar name="JS" dataKey="js" stackId="a" fill={intentPrimaryHex70} />
        )}
        {showGQL && (
          <Bar
            name="GraphQL"
            dataKey="graphQL"
            stackId="a"
            fill={showJS ? intentPrimaryHex50 : intentPrimaryHex70}
          />
        )}
      </BarChartCard>

      <BarChartCard title="Hash requests" data={chartData}>
        <Bar name="Hash" dataKey="hash" stackId="a" fill={intentPrimaryHex} />
      </BarChartCard>

      <BarChartCard title="Analytics events" data={chartData}>
        <Bar
          name="Exposures"
          dataKey="exposures"
          stackId="a"
          fill={intentPrimaryHex}
        />
        <Bar
          name="Events"
          dataKey="events"
          stackId="a"
          fill={intentPrimaryHex70}
        />
      </BarChartCard>
    </>
  );
}

function ProjectSelect({
  projects,
  selectedProjectIds,
  setSelectedProjectIds,
}: {
  projects: { id: string; name: string }[] | null;
  selectedProjectIds: Set<string>;
  setSelectedProjectIds: (newIds: Set<string>) => void;
}): React.ReactElement | null {
  const projectIdToName = projects
    ? Object.fromEntries(projects.map((project) => [project.id, project.name]))
    : {};
  return (
    <TopBarDropdown
      value={{
        label:
          selectedProjectIds.size > 0
            ? selectedProjectIds
                .values()
                .map((id) => projectIdToName[id])
                .toArray()
                .join(", ")
            : "Filter by project",
        value: "",
      }}
      placeholder=""
      options={{
        type: "options",
        options:
          projects?.map((project) => ({
            value: project.id,
            label: project.name,
            isSelected: selectedProjectIds.has(project.id),
          })) ?? [],
      }}
      onChange={(selectedLabel) => {
        if (!selectedLabel) {
          return;
        }
        const updatedSet = new Set(selectedProjectIds);
        if (updatedSet.has(selectedLabel.value)) {
          updatedSet.delete(selectedLabel.value);
        } else {
          updatedSet.add(selectedLabel.value);
        }
        setSelectedProjectIds(updatedSet);
      }}
      dropdownStyle={{
        hideSearch: false,
        buttonClassName: `font-medium disableBgWhenSelected ${selectedProjectIds.size > 0 ? "text-tx-default" : ""} bg-white border rounded-lg py-[6.25px] max-w-[250px]`,
        panelClassName: "pt-1 data-top:pb-1",
        muted: "all",
        caret: "filter",
      }}
      multiSelect
      isLoading={projects === null}
    />
  );
}

function BarChartCard({
  title,
  data,
  children,
}: {
  title: string;
  data: { name: string }[] | null;
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <Card className="mb-10" layout="none">
      <Label type="title1" className="mb-5">
        {title}
      </Label>
      {data ? (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              tickFormatter={(tick) => {
                return tick.toLocaleString();
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {children}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Skeleton height={400} />
      )}
    </Card>
  );
}

function CustomTooltip({
  active,
  payload,
  label,
}: {
  active?: boolean;
  payload?: { name: string; fill: string; value: number }[];
  label?: string;
}): React.ReactElement | null {
  if (!active || !payload) {
    return null;
  }

  return (
    <div className="rounded-lg border border-bd-darker bg-white p-3">
      <p>
        <strong>{label}</strong>
      </p>
      {payload.map(({ name, value, fill: color }) => (
        <p key={`tooltip-item-${name}-${value}`} style={{ color }}>
          {name}: <strong>{value.toLocaleString()}</strong>
        </p>
      ))}
    </div>
  );
}

function sumUsageForType(data: ProjectUsage[], usageType: UsageType): number {
  return data.reduce((total, projectUsage) => {
    return (
      total +
      projectUsage.values.reduce((value, usage) => {
        if (usage.type !== usageType) {
          return value;
        }
        return value + usage.value;
      }, 0)
    );
  }, 0);
}
