import { IconWeight } from "@phosphor-icons/react";
import { blueHex } from "../../lib/constants";

export function iconColor(isSelected: boolean): string | undefined {
  return isSelected ? blueHex : undefined;
}

export function iconWeight(isSelected: boolean): IconWeight | undefined {
  return isSelected ? "fill" : undefined;
}

export type IconSize = "x-small" | "small" | "medium" | "large";
