/* eslint-disable no-plusplus */
import {
  CaretDown,
  CaretUpDown,
  Check,
  FunnelSimple,
  Plus,
} from "@phosphor-icons/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  lighterGreyHex,
  greyHex,
  lightGrey1Hex,
  blueHex,
  interFontFamily,
  mediumFontSize,
} from "../lib/constants";
import SearchInput from "./SearchInput";
import matchesSearch from "../lib/generic/matchesSearch";
import { EditTracking } from "../lib/types";
import twMerge from "../lib/twMerge";
import Button from "./buttons/Button";
import {
  Intent,
  intentToActiveClassName,
  intentToBorderClassName,
  intentToHexColor,
} from "./intent";

type Options<TValue> =
  | { type: "options"; options: LabeledOption<TValue>[] }
  | { type: "groups"; groups: LabeledOptionGroup<TValue>[] };

export type LabeledOption<TValue> = {
  label: string;
  subtitle?: string;
  icon?: React.ReactNode;
  selectedIcon?: React.ReactNode;
  value: TValue;
  isSelected?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  showIconWhenSelected?: boolean;
};

export type LabeledOptionGroup<TValue> = {
  label: string;
  newOption?: () => void;
  options: LabeledOption<TValue>[];
};

export type DropdownStyle = {
  muted?: "none" | "button" | "all";
  caret?: "upDown" | "down" | "downLarge" | "filter" | null;
  alignment?: "start" | "end";
  minWidth?: number;
  sectionMaxHeight?: number;
  buttonPrefix?: React.ReactNode;
  buttonClassName?: string;
  openButtonClassName?: string;
  subtitleClassName?: string;
  panelClassName?: string;
  optionClassName?: string;
  caretClassName?: string;
  openCaretClassName?: string;
  hideSearch?: boolean;
  showButtonSubtitle?: boolean;
  scrollToPosition?: ScrollLogicalPosition;
};

export type TopBarDropDownProps<TValue> = {
  readOnly?: boolean;
  intent?: Intent;
  value: LabeledOption<TValue> | null;
  isLoading?: boolean;
  placeholder: string;
  options: Options<TValue>;
  onChange: (newOption: LabeledOption<TValue> | null) => void;
  dropdownStyle?: DropdownStyle;
  editTracking?: EditTracking;
  onNoItemFound?: (args?: string[]) => void;
  searchInputPlaceholder?: string;
  onOpen?: () => void;
  onClose?: () => void;
  focusButtonOnMount?: boolean;
  autoFocus?: boolean;
  disableDebouncedSearch?: boolean;
  multiSelect?: boolean;
};

export default function TopBarDropdown<TValue>(
  props: TopBarDropDownProps<TValue>
): React.ReactElement {
  return (
    <Popover className="relative flex flex-col items-stretch">
      {({ open }) => {
        return <TopBarDropdownInner {...props} isOpen={open} />;
      }}
    </Popover>
  );
}

function TopBarDropdownInner<TValue>({
  isOpen,
  readOnly,
  intent = "neutral",
  value,
  isLoading,
  placeholder,
  options,
  onChange,
  dropdownStyle,
  editTracking,
  onNoItemFound,
  searchInputPlaceholder,
  onOpen,
  onClose,
  autoFocus,
  focusButtonOnMount,
  disableDebouncedSearch,
  multiSelect,
}: TopBarDropDownProps<TValue> & {
  isOpen: boolean;
}): React.ReactElement {
  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [onOpen, isOpen]);
  useEffect(() => {
    if (editTracking) {
      editTracking.track((previous) => {
        if (isOpen && !previous.has(editTracking.id)) {
          return new Set([...previous, editTracking.id]);
        }
        if (!isOpen && previous.has(editTracking.id)) {
          return new Set([...previous].filter((id) => id !== editTracking.id));
        }
        return previous;
      });
    }
    if (!isOpen) {
      setFocusedIndex(null);
    }
  }, [editTracking, isOpen]);

  const {
    sectionMaxHeight,
    minWidth: baseMinWidth,
    muted = "none",
    caret = "upDown",
    alignment = "start",
    buttonPrefix,
    buttonClassName,
    openButtonClassName,
    subtitleClassName,
    panelClassName,
    optionClassName,
    caretClassName,
    openCaretClassName,
    hideSearch,
    showButtonSubtitle,
    scrollToPosition,
  } = dropdownStyle || {};

  const [searchText, _setSearchText] = useState("");
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  function setSearchText(newText: string): void {
    setFocusedIndex(null);
    _setSearchText(newText);
  }
  function setOption(newOption: LabeledOption<TValue> | null): void {
    setSearchText("");
    onChange(newOption);
  }

  const groupOptions: LabeledOptionGroup<TValue>[] = (
    options.type === "options"
      ? [{ label: "", options: options.options }]
      : options.groups
  ).map((group) => ({
    ...group,
    options: group.options.map((option) => ({
      ...option,
      isSelected: option.isSelected || option.value === value?.value,
    })),
  }));

  const filteredGroupOptions: LabeledOptionGroup<TValue>[] = groupOptions.map(
    (group) => {
      return {
        ...group,
        options: group.options.filter((option) =>
          matchesSearch(searchText, [
            option.label,
            ...(option.subtitle ? [option.subtitle] : []),
          ])
        ),
      };
    }
  );
  const filteredValuesLength = filteredGroupOptions.reduce(
    (length, group) => length + group.options.length,
    0
  );

  const focusedOption =
    focusedIndex !== null
      ? focusedIndex < filteredValuesLength
        ? filteredGroupOptions.reduce<{
            result: LabeledOption<TValue> | null;
            index: number;
          }>(
            ({ result, index }, current) => {
              if (result) {
                return { result, index };
              }
              if (index < current.options.length) {
                return { result: current.options[index], index: 0 };
              }
              return { result: null, index: index - current.options.length };
            },
            { result: null, index: focusedIndex }
          ).result
        : null
      : null;
  const focusedValue = focusedOption !== null ? focusedOption.value : null;

  useEffect(() => {
    if (
      !autoFocus ||
      filteredGroupOptions.every((group) => group.options.length === 0) ||
      focusedIndex ||
      focusedIndex === 0
    ) {
      return;
    }
    for (let i = 0; i < filteredGroupOptions.length; i++) {
      for (let j = 0; j < filteredGroupOptions[i].options.length; j++) {
        if (filteredGroupOptions[i].options[j].isSelected) {
          setFocusedIndex(
            filteredGroupOptions
              .slice(0, i)
              .reduce((acc, group) => acc + group.options.length, 0) + j
          );
          return;
        }
      }
    }
    setFocusedIndex(null);
  }, [autoFocus, filteredGroupOptions, focusedIndex]);

  const [minWidth, setMinWidth] = useState(baseMinWidth);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (minWidth === undefined || !buttonRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      setMinWidth(buttonRef.current?.offsetWidth ?? minWidth);
    });
    resizeObserver.observe(buttonRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!focusButtonOnMount) {
      return;
    }
    console.log("Focus");
    buttonRef.current?.focus();
  }, [focusButtonOnMount]);

  const panelRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <PopoverButton
        ref={buttonRef}
        disabled={readOnly || isLoading}
        className={twMerge(
          `flex select-none flex-row items-center gap-2 rounded-md px-2 ${buttonClassName?.includes("disableBgWhenSelected") ? "py-[5px]" : "py-[8px]"}
          font-inter text-base font-semibold leading-[17px] outline-none
          ${!readOnly && !isLoading ? "cursor-pointer hover:bg-bg-pressed" : ""}
            ${muted !== "none" || !value?.label ? "text-tx-muted" : ""}`,
          ...(intent !== "neutral"
            ? [
                intentToBorderClassName(intent),
                intentToActiveClassName(intent),
                intent === "danger" && "shadow-inputs-sm-error",
              ]
            : []),
          buttonClassName || "",
          isOpen && openButtonClassName
        )}
        style={{
          justifyContent: "space-between",
          ...(baseMinWidth && minWidth ? { minWidth } : {}),
        }}
      >
        <div
          className={`grid grid-cols-[auto,1fr] items-center ${buttonClassName?.includes("disableBgWhenSelected") ? "gap-0" : "gap-2"} `}
        >
          {isLoading ? (
            <Skeleton
              className="col-span-2 rounded-lg"
              height={13}
              width={80}
            />
          ) : (
            <>
              {buttonPrefix || null}
              {value?.icon && value.showIconWhenSelected ? value.icon : null}
              <div className="grid auto-rows-[1fr]">
                <div className="truncate text-start">
                  {value?.label || placeholder}
                </div>
                {showButtonSubtitle && value?.subtitle && (
                  <div
                    className={`truncate text-sm font-medium text-tx-muted
                           ${subtitleClassName || ""}`}
                  >
                    {value.subtitle}
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {isLoading || readOnly || caret === null ? null : caret === "upDown" ? (
          <CaretUpDown
            color={
              intent === "neutral" ? lighterGreyHex : intentToHexColor(intent)
            }
            weight="bold"
            className={twMerge(caretClassName, isOpen && openCaretClassName)}
          />
        ) : caret === "filter" ? (
          <FunnelSimple
            color={
              intent === "neutral" ? lighterGreyHex : intentToHexColor(intent)
            }
            weight="regular"
          />
        ) : (
          <CaretDown
            color={
              intent === "neutral" ? lighterGreyHex : intentToHexColor(intent)
            }
            weight={caret === "downLarge" ? "bold" : "fill"}
            size={caret === "downLarge" ? 12 : 8}
            className={twMerge(caretClassName, isOpen && openCaretClassName)}
          />
        )}
      </PopoverButton>

      <PopoverPanel
        ref={panelRef}
        anchor={alignment === "start" ? "bottom start" : "bottom end"}
        className={twMerge(
          "group absolute z-200 -mx-3 select-none px-3 pb-6 pt-2 font-inter data-top:pb-2 data-top:pt-6",
          panelClassName || ""
        )}
      >
        {({ close: closePanel }) => {
          function close(): void {
            onClose?.();
            closePanel();
          }
          return (
            <div
              style={{ minWidth }}
              className="flex flex-col overflow-hidden rounded-[10px] border bg-white shadow-dropdown group-data-top:shadow-dropdown-top"
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" &&
                  filteredValuesLength === 0 &&
                  onNoItemFound
                ) {
                  onNoItemFound([searchText]);
                  setSearchText("");
                  setFocusedIndex(null);
                  return;
                }
                if (filteredValuesLength === 0) {
                  return;
                }
                if (event.key === "Enter" && focusedOption !== null) {
                  event.preventDefault();
                  if (!focusedOption.isSelected || multiSelect) {
                    setOption(focusedOption);
                  }
                  close();
                  return;
                }
                if (event.key === "ArrowDown") {
                  if (
                    focusedIndex === null ||
                    focusedIndex === filteredValuesLength - 1
                  ) {
                    setFocusedIndex(0);
                    return;
                  }
                  setFocusedIndex(focusedIndex + 1);
                  return;
                }

                if (event.key === "ArrowUp") {
                  if (focusedIndex === null || focusedIndex === 0) {
                    setFocusedIndex(filteredValuesLength - 1);
                    return;
                  }
                  setFocusedIndex(focusedIndex - 1);
                }
              }}
            >
              {!hideSearch && (
                <SearchInput
                  focusOnMount
                  searchText={searchText}
                  setSearchText={setSearchText}
                  noFocusHighlight
                  style={{
                    border: "none",
                    borderRadius: 0,
                    borderBottom: `1px solid ${greyHex}`,
                    maxWidth: "inherit",
                    backgroundColor: lightGrey1Hex,
                    fontFamily: interFontFamily,
                    fontSize: mediumFontSize,
                  }}
                  placeholder={searchInputPlaceholder}
                  disableDebounce={disableDebouncedSearch}
                />
              )}
              <div
                className={`flex flex-col p-1 ${
                  !sectionMaxHeight ? "max-h-[280px] overflow-y-auto" : ""
                }`}
              >
                <div className="flex flex-col">
                  {filteredGroupOptions.map((group) => (
                    <>
                      {group.label && (
                        <div
                          className="mb-2 mt-2 flex flex-row items-center justify-between px-2
                             text-base leading-3 text-tx-muted"
                        >
                          {group.label}
                          {group.newOption && (
                            <div
                              className="cursor-pointer rounded p-[2px] hover:bg-bg-pressed"
                              onClick={() => {
                                group.newOption?.();
                                close();
                              }}
                            >
                              <Plus
                                weight="bold"
                                color={lighterGreyHex}
                                size={12}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      <TopBarOptions
                        key={group.label}
                        maxHeight={sectionMaxHeight}
                        focusedValue={focusedValue}
                        options={group.options}
                        setOption={setOption}
                        optionClassName={optionClassName}
                        subtitleClassName={subtitleClassName}
                        muted={muted === "all"}
                        scrollToPosition={scrollToPosition}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        onNoItemFound={onNoItemFound}
                        close={close}
                        buttonClassName={buttonClassName}
                        panelRef={panelRef}
                        multiSelect={multiSelect}
                      />
                    </>
                  ))}
                </div>
              </div>
            </div>
          );
        }}
      </PopoverPanel>
    </>
  );
}

function TopBarOptions<TValue>({
  maxHeight,
  focusedValue,
  options,
  setOption,
  muted,
  optionClassName,
  subtitleClassName,
  scrollToPosition,
  onNoItemFound,
  searchText,
  close,
  setSearchText,
  buttonClassName,
  panelRef,
  multiSelect,
}: {
  maxHeight?: number;
  focusedValue: TValue | null;
  options: LabeledOption<TValue>[];
  setOption: (newOption: LabeledOption<TValue> | null) => void;
  muted: boolean;
  optionClassName?: string;
  subtitleClassName?: string;
  scrollToPosition?: ScrollLogicalPosition;
  onNoItemFound?: ((args?: string[]) => void) | undefined;
  searchText: string;
  close: () => void;
  setSearchText: (newSearchText: string) => void;
  buttonClassName?: string;
  panelRef?: React.RefObject<HTMLDivElement>;
  multiSelect?: boolean;
}): React.ReactElement {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        panelRef?.current &&
        !panelRef?.current.contains(event.target as Node)
      ) {
        close();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [close, panelRef]);

  return (
    <div
      className="flex flex-col gap-[2px] overflow-auto"
      style={{ maxHeight }}
    >
      {options.length === 0 &&
        (onNoItemFound ? (
          <Button
            icon={<Plus weight="regular" className="h-4 w-4 text-tx-muted" />}
            text="Create new label: "
            className="!justify-start font-medium text-tx-default"
            onClick={() => {
              onNoItemFound([searchText]);
              setSearchText("");
            }}
            isFocused
          >
            <div className="text-tx-muted">{`“${searchText}”`}</div>
          </Button>
        ) : (
          <div className="py-2 text-center text-sm italic leading-normal text-tx-muted">
            No search matches
          </div>
        ))}
      {options.map((option) => (
        <OptionRow
          focusedValue={focusedValue}
          option={option}
          setOption={setOption}
          close={close}
          optionClassName={optionClassName}
          subtitleClassName={subtitleClassName}
          muted={muted}
          scrollToPosition={scrollToPosition}
          isSelected={option.isSelected}
          buttonClassName={buttonClassName}
          multiSelect={multiSelect}
        />
      ))}
    </div>
  );
}

function OptionRow<TValue>({
  focusedValue,
  option,
  setOption,
  close,
  optionClassName,
  subtitleClassName,
  muted,
  scrollToPosition,
  isSelected,
  buttonClassName,
  multiSelect,
}: {
  focusedValue: TValue | null;
  option: LabeledOption<TValue>;
  setOption: (newOption: LabeledOption<TValue> | null) => void;
  close: () => void;
  optionClassName?: string;
  subtitleClassName?: string;
  muted: boolean;
  scrollToPosition?: ScrollLogicalPosition;
  isSelected?: boolean;
  buttonClassName?: string;
  multiSelect?: boolean;
}): React.ReactElement {
  const isFocused = option.value === focusedValue;
  const isDisabled = option.disabled;

  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isSelected || isFocused) {
      const el = divRef.current;
      if (el) {
        el.scrollIntoView({
          block: scrollToPosition || (isSelected ? "start" : "center"),
        });
      }
    }
  }, [scrollToPosition, isSelected, isFocused]);

  return (
    <div
      ref={divRef}
      className={twMerge(
        `grid grid-cols-[1fr,auto] items-center justify-between gap-2 rounded-md py-[8px] pl-2
         pr-[10px] text-base font-semibold leading-normal
          ${
            isSelected && !buttonClassName?.includes("disableBgWhenSelected")
              ? "text-intent-primary"
              : muted || isDisabled
                ? buttonClassName?.includes("disableBgWhenSelected")
                  ? "font-medium"
                  : "text-tx-muted"
                : ""
          }
          ${
            isFocused
              ? "bg-bg-pressed "
              : isSelected &&
                  !buttonClassName?.includes("disableBgWhenSelected")
                ? "bg-bg-primary"
                : focusedValue === null && !isDisabled
                  ? " hover:bg-bg-pressed"
                  : ""
          }
          ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}`,
        optionClassName || ""
      )}
      onClick={
        !isDisabled
          ? () => {
              if (!isSelected || multiSelect) {
                setOption(option);
              }
              close();
            }
          : undefined
      }
    >
      <div className="grid grid-cols-[auto,1fr] items-center gap-2">
        {(isSelected && option.selectedIcon) || option.icon || null}
        <div className="grid auto-rows-[1fr] leading-[17px]">
          <span className="truncate">{option.label}</span>

          {(option.subtitle || (isDisabled && option.disabledMessage)) && (
            <p
              className={`${
                isSelected ? "text-intent-primary" : "text-tx-muted"
              } truncate pr-px text-sm font-medium
              ${subtitleClassName || ""}
              ${isDisabled ? "italic" : ""}`}
            >
              {isDisabled ? option.disabledMessage : option.subtitle}
            </p>
          )}
        </div>
      </div>
      {isSelected && <Check color={blueHex} weight="bold" size={12} />}
    </div>
  );
}
