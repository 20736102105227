import {
  Expression,
  ListExpression,
  Permissions,
  getBooleanExpression,
  uniqueId,
} from "@hypertune/sdk/src/shared";
import React from "react";
import {
  canWrite,
  isEmptyPermissions,
  kilSwitchExpressionNote,
  queryObjectTypeName,
} from "@hypertune/shared-internal";
import { Copy, ToggleRight } from "@phosphor-icons/react";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import wrapExpressionWithComparison from "@hypertune/shared-internal/src/expression/wrapExpressionWithComparison";
import isIfElseExpression from "@hypertune/shared-internal/src/expression/isIfElseExpression";
import getDefaultExpression from "@hypertune/shared-internal/src/expression/getDefaultExpression";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import {
  ExpressionControlContext,
  LiftFunction,
  VariableContext,
} from "../../../../lib/types";
import OptionsButton, { OptionsButtonItem } from "./OptionsButton";
import ExpressionNoteModal from "./ExpressionNoteModal";
import ExpressionPermissionsModal from "./ExpressionPermissionsModal";
import isReadOnly from "../../../../lib/expression/isReadOnly";
import TextInputModal from "../../../../components/TextInputModal";
import { baseDarkGreyHex } from "../../../../lib/constants";
import getListExpressionItemsAndSetItemsFunction, {
  duplicateItemInListExpression,
} from "../../../../lib/expression/getListExpressionItemsAndSetItemsFunction";
import { useAppDispatch } from "../../../../app/hooks";
import { setListImportModalState } from "../../projectSlice";
import { useHypertune } from "../../../../generated/hypertune.react";

export default function ExpressionControlOptionsButton({
  context,
  parentResolvedPermissions,
  expression,
  setExpression,
  lift,
  parentExpression,
  setParentExpression,
  variables,
  variableContext,
}: {
  context: ExpressionControlContext;
  parentResolvedPermissions: Permissions;
  expression: Expression | null;
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  parentExpression: Expression | null;
  setParentExpression?: (newParentExpression: Expression | null) => void;
  variables: VariableMap;
  variableContext?: VariableContext;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const hypertune = useHypertune();
  const [isNoteModalVisible, setIsNoteModalVisible] =
    React.useState<boolean>(false);
  const [isPermissionsModalVisible, setIsPermissionsModalVisible] =
    React.useState<boolean>(false);
  const [isCreateVariableModalVisible, setIsCreateVariableModalVisible] =
    React.useState<boolean>(false);

  if (
    context.hideOptions ||
    !expression ||
    (parentExpression?.type === "SwitchExpression" &&
      expression.type === "BooleanExpression" &&
      expression.metadata?.note === kilSwitchExpressionNote)
  ) {
    return null;
  }

  const readOnly = isReadOnly(context);

  const items: OptionsButtonItem[] = [];

  if (!readOnly && expression.type === "ComparisonExpression") {
    items.push({
      icon: <p className="text-2xs leading-none text-base-dark-grey">&&</p>,
      label: "AND",
      onClick: () => {
        setExpression(wrapExpressionWithComparison(expression, "AND"));
      },
    });
  }
  if (
    !readOnly &&
    expression.type === "ComparisonExpression" &&
    parentExpression?.type !== "SwitchExpression"
  ) {
    items.push({
      icon: <p className="text-2xs leading-none text-base-dark-grey">| |</p>,
      label: "OR",
      onClick: () => {
        setExpression(wrapExpressionWithComparison(expression, "OR"));
      },
    });
  }

  if (
    !readOnly &&
    // The user should not be able to delete this expression if they can't write
    // on the parent, as otherwise they cannot recover from this situation
    // (because they can't then insert something at this position)
    canWrite(context.meId, parentResolvedPermissions) &&
    expression.type !== "FunctionExpression" &&
    !(
      expression.type === "ObjectExpression" &&
      expression.valueType.objectTypeName === queryObjectTypeName
    ) &&
    !(
      expression.type === "ComparisonExpression" &&
      parentExpression?.type === "SwitchExpression"
    ) &&
    !(
      parentExpression?.type === "ComparisonExpression" &&
      expression.type !== "ApplicationExpression" &&
      parentExpression.a?.id === expression.id
    )
  ) {
    items.push({
      iconImgSrc: "/trash.svg",
      label: variableContext ? "Delete contents" : "Delete",
      onClick: () => {
        setExpression(null);
      },
    });
  }
  if (
    !readOnly &&
    parentExpression &&
    setParentExpression &&
    context.setSchemaSplitsAndEventTypes &&
    expression.valueType.type === "ObjectValueType"
  ) {
    const { items: expressionItems, setItems: setParentItems } =
      getListExpressionItemsAndSetItemsFunction(parentExpression);
    if (expressionItems) {
      items.push({
        icon: <Copy size={12} color={baseDarkGreyHex} weight="regular" />,
        label: "Duplicate",
        onClick: () => {
          if (context.setSchemaSplitsAndEventTypes) {
            const { newExpressionItems, newSplits, newEventTypes, newSchema } =
              duplicateItemInListExpression(
                context.commitContext.schema,
                expressionItems,
                expression,
                context.commitContext
              );
            context.setSchemaSplitsAndEventTypes(
              newSchema,
              { ...context.commitContext.splits, ...newSplits },
              { ...context.commitContext.eventTypes, ...newEventTypes }
            );
            setParentExpression(setParentItems(newExpressionItems));
          }
        },
      });
    }
  }

  if (!readOnly) {
    items.push({
      iconImgSrc: "/note.svg",
      label: `${expression.metadata?.note ? "Edit" : "Add"} note`,
      onClick: () => {
        setIsNoteModalVisible(true);
      },
    });
  }

  // We don't want to show the permissions option if we're in the debug editor
  if (!context.ignoreErrors && !context.disablePermissionsManagement) {
    const permissions = expression.metadata?.permissions;
    items.push({
      iconImgSrc: "/permissions.svg",
      label: `${
        readOnly ? "View" : isEmptyPermissions(permissions) ? "Set" : "Edit"
      } permissions`,
      onClick: () => {
        setIsPermissionsModalVisible(true);
      },
    });
  }

  if (!readOnly && variableContext) {
    items.unshift({
      iconImgSrc: "/drop.svg",
      label: "Drop variable",
      onClick: () => {
        variableContext.drop();
      },
    });
  }

  const canLift =
    !readOnly &&
    !context.disableVariableCreation &&
    parentExpression &&
    expression.type !== "VariableExpression" &&
    expression.type !== "FunctionExpression" &&
    isValueTypeValid(context.commitContext.schema, expression.valueType);

  function doLift(newVariableName: string | null, isNew: boolean): void {
    if (!expression) {
      return;
    }
    lift({
      argument: expression,
      replaceArgument: (variable) => variable,
      replacedVariableIdToNewVariable: {},
      newVariableName,
      isNew,
      keepInObjectField: isNew && !!context.keepInObjectField,
    });
  }

  if (canLift && !variableContext) {
    items.push({
      iconImgSrc: "/function.svg",
      label:
        expression.type === "ListExpression"
          ? "Convert to list variable"
          : expression.type === "ComparisonExpression"
            ? "Convert to segment variable"
            : "Convert to variable",
      onClick: () => {
        setIsCreateVariableModalVisible(true);
      },
    });
  }

  if (canLift && !context.disableVariableLift && variableContext) {
    items.unshift({
      iconImgSrc: "/up.svg",
      label: "Lift to higher scope",
      onClick: () => {
        doLift(variableContext.name, false);
      },
    });
  }

  if (!readOnly && expression.type === "ListExpression") {
    if (
      !context.hideCSVImportButton &&
      hypertune.enableListCSVImport({ fallback: false }) &&
      expression.valueType.itemValueType.type !== "ListValueType"
    ) {
      items.push({
        iconImgSrc: "/plus.svg",
        label: "Import from CSV",
        onClick: () =>
          dispatch(
            setListImportModalState({
              listValueType: expression.valueType,
              addItems: (newItems) =>
                setExpression({
                  ...expression,
                  items: expression.items.concat(newItems),
                }),
              meId: context.meId,
              commitContext: context.commitContext,
            })
          ),
      });
    }
    items.push({
      iconImgSrc: "/plus.svg",
      label: "Add empty expression",
      onClick: () => {
        setExpression({
          ...expression,
          items: [...expression.items, null],
        });
      },
    });
  }
  if (
    !readOnly &&
    expression.type === "StringConcatExpression" &&
    expression.strings !== null &&
    expression.strings.type === "ListExpression"
  ) {
    items.push({
      iconImgSrc: "/plus.svg",
      label: "Add empty expression",
      onClick: () => {
        const stringsList = expression.strings as ListExpression;

        setExpression({
          ...expression,
          strings: {
            ...stringsList,
            items: [...stringsList.items, null],
          },
        });
      },
    });
  }
  if (
    !readOnly &&
    expression.type === "SwitchExpression" &&
    isIfElseExpression(expression) &&
    (expression.cases.length === 0 ||
      expression.cases[0].when?.metadata?.note !== kilSwitchExpressionNote)
  ) {
    items.push({
      icon: <ToggleRight size={12} color={baseDarkGreyHex} weight="regular" />,
      label: "Add kill switch",
      onClick: () => {
        const when = {
          ...getBooleanExpression(true),
          metadata: { note: kilSwitchExpressionNote },
        };
        context.setExpressionEditorState({
          ...context.expressionEditorState,
          selectedItem: { type: "expression", id: when.id },
        });
        setExpression({
          ...expression,
          cases: [
            {
              id: uniqueId(),
              when,
              then: getDefaultExpression(
                context.commitContext.schema,
                variables,
                getConstraintFromValueType(expression.valueType),
                new Set<string>()
              ),
            },
            ...expression.cases,
          ],
        });
      },
    });
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <OptionsButton items={items} />
      {isNoteModalVisible ? (
        <ExpressionNoteModal
          expression={expression}
          setExpression={setExpression}
          onClose={() => {
            setIsNoteModalVisible(false);
          }}
        />
      ) : null}
      {isPermissionsModalVisible ? (
        <ExpressionPermissionsModal
          context={context}
          parentResolvedPermissions={parentResolvedPermissions}
          expression={expression}
          setExpression={setExpression}
          onClose={() => {
            setIsPermissionsModalVisible(false);
          }}
        />
      ) : null}
      {isCreateVariableModalVisible ? (
        <TextInputModal
          title="Create variable"
          textInputPlaceholder="Enter a name for this variable, e.g. betaSegment"
          buttonLabel="Create"
          sanitizeText={(text) => text.replace(/\W/g, "")}
          onEnter={(newVariableName) => {
            doLift(newVariableName, true);
            setIsCreateVariableModalVisible(false);
          }}
          onClose={() => {
            setIsCreateVariableModalVisible(false);
          }}
        />
      ) : null}
    </>
  );
}
