import {
  BooleanValueType,
  EnumValueType,
  FloatValueType,
  IntValueType,
  RegexValueType,
  StringValueType,
  ValueType,
} from "@hypertune/sdk/src/shared/types";

export type PrimitiveValueType =
  | BooleanValueType
  | IntValueType
  | FloatValueType
  | StringValueType
  | RegexValueType
  | EnumValueType;

export default function isPrimitiveValueType(valueType: ValueType): boolean {
  return (
    valueType.type === "BooleanValueType" ||
    valueType.type === "IntValueType" ||
    valueType.type === "FloatValueType" ||
    valueType.type === "StringValueType" ||
    valueType.type === "RegexValueType" ||
    valueType.type === "EnumValueType"
  );
}
