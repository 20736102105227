import {
  ApplicationExpression,
  Expression,
  SwitchExpression,
  VariableExpression,
  uniqueId,
} from "@hypertune/sdk/src/shared";
import getComparisonExpression from "@hypertune/shared-internal/src/expression/getComparisonExpression";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import isIfElseExpression from "@hypertune/shared-internal/src/expression/isIfElseExpression";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import { kilSwitchExpressionNote } from "@hypertune/shared-internal";
import getDefaultExpression from "@hypertune/shared-internal/src/expression/getDefaultExpression";
import createApplication from "../../../../lib/expression/createApplication";
import {
  liftPermissionsDeniedErrorMessage,
  plusSymbol,
} from "../../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
} from "../../../../lib/types";
import ExpressionControl from "./ExpressionControl";
import isReadOnly from "../../../../lib/expression/isReadOnly";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import {
  Intent,
  intentToBorderClassName,
  intentToDivideClassName,
  intentToLightBgClassName,
  intentToTextColorName,
} from "../../../../components/intent";
import Button from "../../../../components/buttons/Button";
import twMerge from "../../../../lib/twMerge";
import { useHypertune } from "../../../../generated/hypertune.react";
import EvaluationCount from "./EvaluationCount";
import SortableList from "../../../../components/SortableList";

export default function SwitchExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  lift,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: SwitchExpression;
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const readOnly = isReadOnly(context);
  const intent = context.expressionIdToIntent?.[expression.id] ?? "neutral";

  const thenValueType = isValueTypeValid(
    context.commitContext.schema,
    expression.valueType
  )
    ? expression.valueType
    : null;

  const thenValueTypeConstraint: ValueTypeConstraint = thenValueType
    ? getConstraintFromValueType(thenValueType)
    : { type: "ErrorValueTypeConstraint" };

  const whenValueTypeConstraint: ValueTypeConstraint =
    expression.control &&
    isValueTypeValid(context.commitContext.schema, expression.control.valueType)
      ? getConstraintFromValueType(expression.control.valueType)
      : { type: "ErrorValueTypeConstraint" };

  const isIfElse = isIfElseExpression(expression);

  const firstWhenLabel = isIfElse ? "If" : "When";
  const whenLabel = isIfElse ? "Else If" : "When";
  const newCaseText = isIfElse ? "Rule" : "Case";

  const isExpressionSelectabilityEnabled =
    useHypertune().expressionsSelectability({ fallback: false });

  const defaultExpressionControl = (
    <ExpressionControl
      context={context}
      variables={variables}
      setVariableName={setVariableName}
      valueTypeConstraint={thenValueTypeConstraint}
      expression={expression.default}
      setExpression={(newExpression: Expression | null): void =>
        setExpression({
          ...expression,
          default: newExpression,
        })
      }
      lift={(child): void => {
        if (readOnly) {
          // eslint-disable-next-line no-alert
          alert(liftPermissionsDeniedErrorMessage);
          return;
        }
        function replaceArgument(
          variable: VariableExpression | ApplicationExpression
        ): SwitchExpression {
          const newExpression: SwitchExpression = {
            ...expression,
            default: child.replaceArgument(variable),
          };
          return newExpression;
        }
        // We let the parent lift the default
        lift({
          argument: child.argument,
          replacedVariableIdToNewVariable:
            child.replacedVariableIdToNewVariable,
          replaceArgument,
          newVariableName: child.newVariableName,
          isNew: child.isNew,
          keepInObjectField: child.keepInObjectField,
        });
      }}
      parentExpression={expression}
      setParentExpression={setExpression}
      includeExpressionOption={includeExpressionOption}
      disablePanelOnSelect={isExpressionSelectabilityEnabled}
    />
  );

  return (
    <div
      className={`flex flex-col ${isIfElse && expression.cases.length === 0 ? "gap-2" : "gap-5"}`}
    >
      {isIfElse ? null : (
        <StandaloneLabelledWrapper label="Control" intent={intent}>
          <ExpressionControl
            context={context}
            variables={variables}
            setVariableName={setVariableName}
            valueTypeConstraint={{ type: "AnyValueTypeConstraint" }}
            expression={expression.control}
            setExpression={(newExpression: Expression | null): void =>
              setExpression({ ...expression, control: newExpression })
            }
            lift={(child): void => {
              if (readOnly) {
                // eslint-disable-next-line no-alert
                alert(liftPermissionsDeniedErrorMessage);
                return;
              }
              function replaceArgument(
                variable: VariableExpression | ApplicationExpression
              ): SwitchExpression {
                const newExpression: SwitchExpression = {
                  ...expression,
                  control: child.replaceArgument(variable),
                };
                return newExpression;
              }
              const applicationExpression = createApplication({
                variables,
                rawArgument: child.argument,
                replacedVariableIdToNewVariable:
                  child.replacedVariableIdToNewVariable,
                valueType: expression.valueType,
                replaceArgument,
                newVariableName: child.newVariableName,
                setExpressionEditorSelectedItem: (newSelectedItem) =>
                  context.setExpressionEditorState({
                    ...context.expressionEditorState,
                    selectedItem: newSelectedItem,
                  }),
              });
              setExpression(applicationExpression);
            }}
            parentExpression={expression}
            setParentExpression={setExpression}
            includeExpressionOption={includeExpressionOption}
            disablePanelOnSelect={isExpressionSelectabilityEnabled}
          />
        </StandaloneLabelledWrapper>
      )}
      {expression.cases.length > 1 ? (
        <SortableList<SwitchExpression["cases"][number]>
          disabled={readOnly}
          list={expression.cases}
          setList={(newCases) =>
            setExpression({ ...expression, cases: newCases })
          }
          renderItemComponent={({ index, dragHandle, firstIndex }) => (
            <SwitchExpressionCaseControl
              context={context}
              variables={variables}
              setVariableName={setVariableName}
              expression={expression}
              setExpression={setExpression}
              lift={lift}
              index={index}
              whenLabel={index === firstIndex ? firstWhenLabel : whenLabel}
              whenValueTypeConstraint={whenValueTypeConstraint}
              thenValueTypeConstraint={thenValueTypeConstraint}
              includeExpressionOption={includeExpressionOption}
              dragHandle={dragHandle}
            />
          )}
        />
      ) : expression.cases.length === 1 ? (
        <SwitchExpressionCaseControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          lift={lift}
          index={0}
          whenLabel={firstWhenLabel}
          whenValueTypeConstraint={whenValueTypeConstraint}
          thenValueTypeConstraint={thenValueTypeConstraint}
          includeExpressionOption={includeExpressionOption}
        />
      ) : null}

      {isIfElse && expression.cases.length === 0 ? (
        defaultExpressionControl
      ) : (
        <StandaloneLabelledWrapper
          label="Default"
          intent={
            expression.default?.id
              ? (context.expressionIdToIntent?.[expression.default.id] ??
                "neutral")
              : "neutral"
          }
        >
          {defaultExpressionControl}
          <EvaluationCountPill
            context={context}
            expression={expression.default}
          />
        </StandaloneLabelledWrapper>
      )}
      {!readOnly && thenValueType ? (
        <div className="flex flex-col items-stretch rounded-lg bg-white">
          <Button
            intent="primary"
            weight="minimal"
            className="border-intent-primary/40"
            text={newCaseText}
            icon={plusSymbol}
            onClick={() =>
              setExpression({
                ...expression,
                cases: [
                  ...expression.cases,
                  {
                    id: uniqueId(),
                    when: isIfElse ? getComparisonExpression() : null,
                    then: getDefaultExpression(
                      context.commitContext.schema,
                      {},
                      getConstraintFromValueType(expression.valueType),
                      new Set(),
                      expression.default?.type === "BooleanExpression"
                        ? {
                            defaultBooleanValue: !expression.default.value,
                          }
                        : undefined
                    ),
                  },
                ],
              })
            }
          />
        </div>
      ) : null}
    </div>
  );
}

function SwitchExpressionCaseControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  lift,
  index,
  whenLabel,
  whenValueTypeConstraint,
  thenValueTypeConstraint,
  includeExpressionOption,
  dragHandle,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  /* The parent switch expression this case belongs to. */
  expression: SwitchExpression;
  /* Replace the parent switch expression this case belongs to. */
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  index: number;
  whenLabel: string;
  whenValueTypeConstraint: ValueTypeConstraint;
  thenValueTypeConstraint: ValueTypeConstraint;
  includeExpressionOption: IncludeExpressionOptionFunction;
  dragHandle?: React.ReactNode;
}): React.ReactElement {
  const item = expression.cases[index];
  const readOnly = isReadOnly(context);
  const intent =
    context.expressionIdToIntent?.[`${expression.id}case${item.id}`] ??
    "neutral";

  const isExpressionSelectabilityEnabled =
    useHypertune().expressionsSelectability({ fallback: false });
  return (
    <CaseWrapper intent={intent}>
      <WhenWrapper
        label={whenLabel}
        intent={intent}
        prefixActions={readOnly ? null : dragHandle}
        actions={
          readOnly ? null : (
            <div className="flex flex-row items-center gap-1 opacity-0 group-hover/case:opacity-100">
              <DeleteButton
                size="x-small"
                key={`delete${index.toString()}`}
                className="mr-1"
                onClick={(): void =>
                  setExpression({
                    ...expression,
                    cases: [
                      ...expression.cases.slice(0, index),
                      ...expression.cases.slice(index + 1),
                    ],
                  })
                }
              />
            </div>
          )
        }
      >
        <ExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          valueTypeConstraint={whenValueTypeConstraint}
          expression={item.when}
          setExpression={(newExpression: Expression | null): void =>
            setExpression({
              ...expression,
              cases: [
                ...expression.cases.slice(0, index),
                {
                  ...item,
                  when: newExpression,
                },
                ...expression.cases.slice(index + 1),
              ],
            })
          }
          lift={(child) => {
            if (readOnly) {
              // eslint-disable-next-line no-alert
              alert(liftPermissionsDeniedErrorMessage);
              return;
            }
            function replaceArgument(
              variable: VariableExpression | ApplicationExpression
            ): SwitchExpression {
              const newExpression: SwitchExpression = {
                ...expression,
                cases: [
                  ...expression.cases.slice(0, index),
                  {
                    ...item,
                    when: child.replaceArgument(variable),
                  },
                  ...expression.cases.slice(index + 1),
                ],
              };
              return newExpression;
            }
            // We let the parent lift the when
            lift({
              argument: child.argument,
              replacedVariableIdToNewVariable:
                child.replacedVariableIdToNewVariable,
              replaceArgument,
              newVariableName: child.newVariableName,
              isNew: child.isNew,
              keepInObjectField: child.keepInObjectField,
            });
          }}
          parentExpression={expression}
          setParentExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
          disablePanelOnSelect={isExpressionSelectabilityEnabled}
        />
      </WhenWrapper>
      <LabelledWrapper label="Then" intent={intent}>
        <ExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          valueTypeConstraint={thenValueTypeConstraint}
          expression={item.then}
          setExpression={(newExpression: Expression | null): void =>
            setExpression({
              ...expression,
              cases: [
                ...expression.cases.slice(0, index),
                {
                  ...item,
                  then: newExpression,
                },
                ...expression.cases.slice(index + 1),
              ],
            })
          }
          lift={(child): void => {
            if (readOnly) {
              // eslint-disable-next-line no-alert
              alert(liftPermissionsDeniedErrorMessage);
              return;
            }
            function replaceArgument(
              variable: VariableExpression | ApplicationExpression
            ): SwitchExpression {
              const newExpression: SwitchExpression = {
                ...expression,
                cases: [
                  ...expression.cases.slice(0, index),
                  {
                    ...item,
                    then: child.replaceArgument(variable),
                  },
                  ...expression.cases.slice(index + 1),
                ],
              };
              return newExpression;
            }
            // We let the parent lift the then
            lift({
              argument: child.argument,
              replacedVariableIdToNewVariable:
                child.replacedVariableIdToNewVariable,
              replaceArgument,
              newVariableName: child.newVariableName,
              isNew: child.isNew,
              keepInObjectField: child.keepInObjectField,
            });
          }}
          parentExpression={expression}
          setParentExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
          disablePanelOnSelect={isExpressionSelectabilityEnabled}
        />
        <EvaluationCountPill context={context} expression={item.then} />
      </LabelledWrapper>
    </CaseWrapper>
  );
}

function isKillSwitch(expression: Expression | null): boolean {
  return (
    !!expression &&
    expression.type === "BooleanExpression" &&
    expression.metadata?.note === kilSwitchExpressionNote
  );
}

function CaseWrapper({
  intent,
  children,
}: {
  intent: Intent;
  children: React.ReactNode;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "group/case flex flex-col divide-y rounded-lg border",
        intent === "neutral" ? "bg-bg-light" : intentToLightBgClassName(intent),
        intentToTextColorName(intent),
        intent !== "neutral" ? intentToBorderClassName(intent) : undefined,
        intent !== "neutral" ? intentToDivideClassName(intent) : undefined
      )}
    >
      {children}
    </div>
  );
}

function WhenWrapper({
  label,
  intent,
  prefixActions,
  actions,
  children,
}: {
  label: string;
  intent: Intent;
  prefixActions?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
}): React.ReactElement | null {
  return (
    <div className="flex flex-col">
      <div
        className={`flex flex-row items-center justify-between gap-2 rounded-t-lg border-b px-2 py-1 ${intentToLightBgClassName(intent)} ${intent !== "neutral" ? intentToBorderClassName(intent) : ""} ${intentToTextColorName(intent)}`}
      >
        <div className="flex flex-row items-center gap-1">
          {prefixActions}
          <p>{label}</p>
        </div>
        {actions}
      </div>
      <div className="p-2">{children}</div>
    </div>
  );
}

function StandaloneLabelledWrapper({
  label,
  intent,
  children,
}: {
  label: string;
  intent: Intent;
  children: React.ReactNode;
}): React.ReactElement | null {
  return (
    <LabelledWrapper
      label={label}
      intent={intent}
      className={`relative rounded-lg border ${intent !== "neutral" ? intentToBorderClassName(intent) : ""}`}
    >
      {children}
    </LabelledWrapper>
  );
}

function LabelledWrapper({
  label,
  intent,
  children,
  className,
}: {
  label: string;
  intent: Intent;
  children: React.ReactNode;
  className?: string;
}): React.ReactElement | null {
  return (
    <div
      className={`relative flex flex-col rounded-b-lg ${intentToLightBgClassName(intent)} ${intentToTextColorName(intent)} ${className ?? ""}`}
    >
      <div className="flex flex-row items-start justify-between gap-4 p-2">
        <div className="flex flex-row gap-2 pt-[6px]">
          <p>{label}</p>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}

function EvaluationCountPill({
  context,
  expression,
}: {
  context: ExpressionControlContext;
  expression: Expression | null;
}): React.ReactElement | null {
  if (
    !context.showSwitchEvaluations ||
    !expression ||
    !context.evaluations[expression.id]
  ) {
    return null;
  }
  return (
    <div className="absolute left-full top-0 z-100 pl-3">
      <EvaluationCount
        count={context.evaluations[expression.id]}
        className="rounded-t-[10px] rounded-br-[10px] border bg-white py-[6px] pl-2 pr-4"
      />
    </div>
  );
}
