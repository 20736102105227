import { ArrowRight } from "@phosphor-icons/react";
import { lightGreyHex } from "../../lib/constants";

export type GoToArrowButtonType =
  | "primary"
  | "variable"
  | "event"
  | "test"
  | "ml";

export default function GoToArrowButton({
  onClick,
  type = "primary",
}: {
  onClick: () => void;
  type?: GoToArrowButtonType;
}): React.ReactElement | null {
  return (
    <div
      className="group absolute right-0 top-0 cursor-pointer pr-2 pt-[7px]"
      onClick={onClick}
    >
      <div className="-m-2 p-2">
        <div
          className={`${typeToClassName(type)} -mx-[5px] -my-[4px] rounded-lg px-[5px] py-[4px]`}
        >
          <ArrowRight weight="bold" color={lightGreyHex} />
        </div>
      </div>
    </div>
  );
}

function typeToClassName(type: GoToArrowButtonType): string {
  switch (type) {
    case "primary":
      return "group-hover:filter-intent-primary group-hover:bg-intent-primary/5";
    case "variable":
      return "group-hover:filter-base-pink group-hover:bg-intent-primary/5";
    case "event":
      return "group-hover:filter-intent-success group-hover:bg-intent-success/5";
    case "test":
      return "group-hover:filter-base-purple group-hover:bg-base-purple/5";
    case "ml":
      return "group-hover:filter-type-icon-lavender group-hover:bg-type-icon-lavender/5";
    default: {
      const neverType: never = type;
      throw new Error(`Unexpected go to arrow button type: "${neverType}"`);
    }
  }
}
