import { Schema, SplitMap } from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import React from "react";
import { twMerge } from "tailwind-merge";
import { Empty } from "@phosphor-icons/react";
import { intentPrimaryHex, plusSymbol } from "../../lib/constants";
import { useAppDispatch } from "../../app/hooks";
import { setNewSplitModalState, setNewTypeModalState } from "./projectSlice";
import { Intent } from "../../components/intent";
import { useSplitEditorSetSelectedSplitId } from "./split/splitHooks";
import { useSchemaEditorSetSelectedType } from "./schema/schemaHooks";
import TypeIcon from "../../components/icons/TypeIcon";
import GoToArrowButton from "../../components/buttons/GoToArrowButton";
import TopBarDropdown, { LabeledOption } from "../../components/TopBarDropdown";
import IconContainer from "../../components/icons/IconContainer";

export default function IdSelector({
  context,
  source,
  selectedId,
  setSelectedId,
  readOnly,
  showGoToArrow,
  allowEmpty,
  hideNewOption,
  style,
  intent = "neutral",
  variant = "normal",
  buttonClassName,
}: {
  context: { splits: SplitMap; schema: Schema };
  source: "splits" | "eventTypes";
  selectedId: string | null;
  setSelectedId: (newSelectedId: string | null) => void;
  readOnly: boolean;
  showGoToArrow?: boolean;
  allowEmpty?: boolean;
  hideNewOption?: boolean;
  style?: React.CSSProperties;
  intent?: Intent;
  variant?: "normal" | "with-error";
  buttonClassName?: string;
}): React.ReactElement {
  const dispatch = useAppDispatch();
  const setSelectedSplitId = useSplitEditorSetSelectedSplitId({
    setSplitView: true,
  });
  const setSelectedType = useSchemaEditorSetSelectedType({
    setSchemaView: true,
  });

  const noun = source === "splits" ? "split" : "event type";
  const label = toStartCase(noun);
  const map: IdMap =
    source === "splits" ? context.splits : getEventIdMap(context.schema);

  function getLabeledOption(
    id: string,
    icon?: React.ReactNode
  ): LabeledOption<string> {
    const entry = map[id];
    return {
      value: id,
      label: entry
        ? source === "splits"
          ? entry.name
          : toStartCase(entry.name)
        : `(Invalid) ${label} ${id}`,
      icon,
      showIconWhenSelected: true,
    };
  }

  const createOptionValue = "--new";
  const createOption: LabeledOption<string> = {
    value: createOptionValue,
    label: `${plusSymbol} New ${label}`,
  };
  const emptyOptionValue = "--empty";
  const emptyOption: LabeledOption<string> = {
    value: emptyOptionValue,
    label: "None",
    icon: (
      <IconContainer
        className="border-[0.5px] border-base-grey-1-dark"
        size="small"
      >
        <Empty weight="regular" size={10} />
      </IconContainer>
    ),
    selectedIcon: (
      <IconContainer
        className="border-[0.5px] border-intent-primary"
        size="small"
      >
        <Empty weight="regular" size={10} color={intentPrimaryHex} />
      </IconContainer>
    ),
    showIconWhenSelected: true,
  };

  const options = [
    ...(source === "splits"
      ? Object.values(context.splits)
          .filter((split) => !split.archived)
          .map((split) =>
            getLabeledOption(
              split.id,
              <TypeIcon type={split.type} size="small" />
            )
          )
      : Object.keys(map).map((eventTypeName) =>
          getLabeledOption(
            eventTypeName,
            <TypeIcon type="event" size="small" />
          )
        )),
    ...(readOnly || !allowEmpty ? [] : [emptyOption]),
    ...(readOnly || hideNewOption ? [] : [createOption]),
  ];
  const emptyValue = allowEmpty ? emptyOption : null;
  const value = selectedId
    ? (options.find((option) => option.value === selectedId) ?? emptyValue)
    : emptyValue;
  const showArrow = showGoToArrow && value;
  const intentWithError =
    variant === "with-error" && !selectedId && !allowEmpty ? "danger" : intent;

  return (
    <div className="group relative" style={style}>
      <TopBarDropdown<string | null>
        readOnly={readOnly}
        intent={intentWithError}
        dropdownStyle={{
          minWidth: 0,
          scrollToPosition: "center",
          buttonClassName: twMerge(
            "border font-medium rounded-lg h-[30px] pr-2",
            variant === "with-error" ? "pl-[6px]" : "pl-2",
            value
              ? variant === "with-error"
                ? source === "eventTypes"
                  ? "text-intent-success"
                  : selectedId && context.splits[selectedId]?.type === "ml"
                    ? "text-type-icon-lavender"
                    : "text-base-purple"
                : "text-tx-default"
              : "",
            intentWithError === "neutral" && variant === "with-error"
              ? source === "eventTypes"
                ? "bg-white hover:border-intent-success group-hover:border-intent-success hover:bg-intent-success/5 focus:border-intent-success focus:shadow-inputs-success-sm"
                : selectedId && context.splits[selectedId]?.type === "ml"
                  ? "bg-white hover:border-type-icon-lavender group-hover:border-type-icon-lavender hover:bg-type-icon-lavender/5 focus:border-type-icon-lavender focus:shadow-inputs-purple-sm"
                  : "bg-white hover:border-base-purple group-hover:border-base-purple hover:bg-base-purple/5 focus:border-base-purple focus:shadow-inputs-lavender-sm"
              : "bg-white hover:border-intent-primary group-hover:border-intent-primary hover:bg-white focus:border-intent-primary focus:shadow-inputs-sm",
            intentWithError === "danger"
              ? "hover:border-intent-danger group-hover:border-intent-danger focus:border-intent-danger"
              : "",
            showArrow ? "pr-[34px]" : "",
            readOnly ? "bg-bg-light" : "",
            buttonClassName
          ),
          openButtonClassName: twMerge(
            intentWithError === "neutral" && variant === "with-error"
              ? source === "eventTypes"
                ? "border-intent-success shadow-inputs-success-sm"
                : selectedId && context.splits[selectedId]?.type === "ml"
                  ? "border-type-icon-lavender shadow-inputs-lavender-sm"
                  : "border-base-purple shadow-inputs-purple-sm"
              : "border-intent-primary shadow-inputs-sm",
            intentWithError === "danger"
              ? "border-intent-danger shadow-inputs-sm-error focus:shadow-inputs-sm-error"
              : ""
          ),
          optionClassName: "font-medium",
          panelClassName: "pt-1 data-top:pb-1",
          caret: variant === "with-error" && value ? null : "downLarge",
        }}
        options={{ type: "options", options }}
        value={value}
        onChange={(option) => {
          if (option && option.value === createOptionValue) {
            if (source === "splits") {
              dispatch(
                setNewSplitModalState({
                  onCreate: (_, newSplitId) => {
                    setSelectedId(newSplitId);
                  },
                })
              );
            } else {
              dispatch(
                setNewTypeModalState({
                  defaultTypeOption: "event",
                  onCreate: (typeOption, newEventName) => {
                    if (typeOption === "event") {
                      setSelectedId(newEventName);
                    }
                  },
                })
              );
            }
            return;
          }
          if (option && option.value === emptyOptionValue) {
            setSelectedId(null);
            return;
          }
          setSelectedId(option ? option.value : null);
        }}
        placeholder={
          options.length === 0 ? `No ${noun}s found` : `Select ${noun}...`
        }
      />
      {showArrow && (
        <GoToArrowButton
          onClick={() => {
            if (!selectedId) {
              return;
            }
            if (source === "splits") {
              setSelectedSplitId(selectedId);
              return;
            }
            setSelectedType({
              type: "event",
              name: selectedId,
              selectedChildName: null,
            });
          }}
          type={
            source === "eventTypes"
              ? "event"
              : selectedId && context.splits[selectedId]?.type === "ml"
                ? "ml"
                : "test"
          }
        />
      )}
    </div>
  );
}

type IdMap = { [id: string]: { id: string; name: string } };

function getEventIdMap(schema: Schema): IdMap {
  return Object.fromEntries(
    Object.entries(schema.objects)
      .filter(([, objectSchema]) => objectSchema.role === "event")
      .map(([name]) => [name, { id: name, name }])
  );
}
