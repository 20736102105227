import { ApolloProvider } from "@apollo/client";
import { init as initFullStory } from "@fullstory/browser";
import { StrictMode } from "react";
import "./index.css";
import "./rsuite.css";
import { createRoot } from "react-dom/client";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { IconContext, IconProps } from "@phosphor-icons/react";
import { Provider as ReduxProvider } from "react-redux";
import { SkeletonTheme } from "react-loading-skeleton";
import getApolloClient from "./lib/query/getApolloClient";
import { initializeAuth } from "./lib/query/auth";
import App from "./app/App";
import { store } from "./app/store";
import LoginPage from "./features/user/LoginPage";
import RequireAuth from "./app/RequireAuth";
import NotFoundPage from "./app/NotFoundPage";
import TeamPage from "./features/business/team/TeamPage";
import ProjectsPage from "./features/business/projects/ProjectsPage";
import SettingsPage from "./features/business/SettingsPage";
import ProjectPage from "./features/project/ProjectPage";
import RegisterPage from "./features/user/register/RegisterPage";
import { greyHex, lightGrey2Hex, lighterGreyHex } from "./lib/constants";
import ErrorPage from "./app/ErrorPage";
import env from "./environment";
import TeamsPage from "./features/business/team/TeamsPage";
import EmailLinkPage from "./features/business/EmailLinkPage";
import getFaro from "./lib/getFaro";
import ProjectRedirect from "./features/project/ProjectRedirect";
import AppHypertuneProvider from "./app/AppHypertuneProvider";
import PersonalizationPage from "./features/user/register/PersonalizationPage";
import PlansPage from "./features/business/billing/PlansPage";
import BillingPage from "./features/business/billing/BillingPage";
import PasswordResetPage from "./features/user/PasswordResetPage";
import { getCookieConsent, onCookieConsent } from "./lib/cookieConsent";
import UsagePage from "./features/business/UsagePage";

function initAnalytics(): void {
  // FullStory
  initFullStory({ orgId: "o-20TC8D-na1" });
  // Grafana Faro
  getFaro();
}

if (env.NAME === "production") {
  if (getCookieConsent()) {
    initAnalytics();
  } else {
    onCookieConsent(initAnalytics);
  }
}

// Apollo

const apolloClient = getApolloClient();

// Auth

initializeAuth();

// Due to a bug in Vite, the debugger cannot set breakpoints correctly after
// hot updating: https://github.com/vitejs/vite-plugin-react/issues/23
// If we there is a flag that indicates a debugger is or will be attached, we
// refresh whenever we would have a hot update to ensure breakpoints are set
// correctly.
// If you're finding reloads while debugging are slow, disable breakpoints while
// making changes, and reenable them once you want to test things out. Reloading
// while there are active breakpoints forces browsers to check each line it
// executes for active breakpoints, making them slow.
if (import.meta.env.VITE_HYPERTUNE_DEBUG) {
  import.meta.hot?.on("vite:beforeUpdate", () => {
    window.location.reload();
  });
}

// Routing

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route path="login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/reset-password" element={<PasswordResetPage />} />
      <Route
        path="/personalize"
        element={
          <RequireAuth>
            <PersonalizationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/"
        element={
          <RequireAuth>
            <ProjectsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/team"
        element={
          <RequireAuth>
            <TeamPage />
          </RequireAuth>
        }
      />
      <Route
        path="/teams"
        element={
          <RequireAuth>
            <TeamsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/invites"
        element={
          <RequireAuth>
            <TeamsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/usage"
        element={
          <RequireAuth>
            <UsagePage />
          </RequireAuth>
        }
      />
      <Route
        path="/settings"
        element={
          <RequireAuth>
            <SettingsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/settings/vercel-edge-config"
        element={
          <RequireAuth>
            <SettingsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/plans"
        element={
          <RequireAuth>
            <PlansPage />
          </RequireAuth>
        }
      />
      <Route
        path="/billing"
        element={
          <RequireAuth>
            <BillingPage />
          </RequireAuth>
        }
      />
      <Route
        path="/projects/:selectedProjectId/:selectedBranchName/:selectedCommitId/:selectedView"
        element={
          <RequireAuth>
            <ProjectPage />
          </RequireAuth>
        }
      />
      <Route
        path="/projects/:selectedProjectId/:selectedBranchName/:selectedCommitId"
        element={<ProjectRedirect />}
      />
      <Route
        path="/projects/:selectedProjectId/:selectedCommitId"
        element={<ProjectRedirect />}
      />
      <Route
        path="/projects/:selectedProjectId"
        element={<ProjectRedirect />}
      />
      <Route
        path="/email/:verifyEmailAndInviteToken"
        element={
          <RequireAuth>
            <EmailLinkPage />
          </RequireAuth>
        }
      />
      <Route
        path="/invites/:businessInviteToken"
        element={
          <RequireAuth>
            <EmailLinkPage />
          </RequireAuth>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);

// eslint-disable-next-line react/jsx-no-constructed-context-values
const iconContext: IconProps = {
  size: 16,
  color: lighterGreyHex,
  weight: "duotone",
};

// React

const root = createRoot(document.getElementById("root") as Element);
root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <ApolloProvider client={apolloClient}>
        <SkeletonTheme baseColor={greyHex} highlightColor={lightGrey2Hex}>
          <IconContext.Provider value={iconContext}>
            <AppHypertuneProvider>
              <RouterProvider router={router} />
            </AppHypertuneProvider>
          </IconContext.Provider>
        </SkeletonTheme>
      </ApolloProvider>
    </ReduxProvider>
  </StrictMode>
);
