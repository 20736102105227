import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Command } from "cmdk";
import {
  ArrowBendDownLeft,
  ArrowDown,
  ArrowUp,
  Backspace,
  BookBookmark,
  ChatTeardropText,
  MagnifyingGlass,
  Plus,
  ClipboardText,
  ChartBar,
  Flag as FlagIcon,
  Hash,
  ArrowsSplit,
} from "@phosphor-icons/react";

import {
  rootObjectTypeName,
  variablePathSuffix,
} from "@hypertune/shared-internal";
import { fieldPathSeparator } from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import Modal from "../../../components/Modal";
import { docsUrl, lighterGreyHex } from "../../../lib/constants";
import Button from "../../../components/buttons/Button";
import ShortcutDefinition from "../../../components/ShortcutDefinition";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  ExpressionNodeMap,
  Flag,
  getFlagsFromTree,
} from "../logic/expression/toTree";
import ValueTypeConstraintIcon from "../logic/expression/ValueTypeConstraintIcon";
import stripRootPrefix from "../../../lib/expression/stripRootPrefix";
import { ProjectQuery } from "../../../generated/graphql";
import { useSelectedAnalyticsViewId } from "../analytics/analyticsHooks";
import useIntercom from "../../../app/useIntercom";
import Spinner from "../../../components/icons/Spinner";
import { useLogicSetSelectedFieldPath } from "../logicHooks";
import {
  setObjectAddFieldModalState,
  setNewVariableModalState,
} from "../projectSlice";
import { setShowNewViewModal } from "../analytics/analyticsSlice";
import { getQueryToken } from "../../../lib/tokens";
import { ProjectView } from "../projectHooks";

export default function CommandPalette({
  project,
  defaultFieldPath,
  expressionTree,
  isVisible,
  setIsVisible,
  setSelectedView,
}: {
  project?: ProjectQuery["project"];
  defaultFieldPath: string[];
  expressionTree: ExpressionNodeMap | null;
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  setSelectedView: (newView: ProjectView) => void;
}): React.ReactElement | null {
  const expression = useAppSelector(
    (state) => state.project.draftCommit?.expression
  );
  const ref = useRef<HTMLDivElement | null>(null);
  const [searchValue, setSearchValue] = useState("");

  const [pages, setPages] = useState<string[]>(["home"]);
  const activePage = pages[pages.length - 1];
  const isHome = activePage === "home";

  const reset = useCallback(() => {
    setSearchValue("");
    setPages(["home"]);
  }, [setSearchValue, setPages]);

  const onClose = useCallback(() => {
    setIsVisible(false);
    reset();
  }, [setIsVisible, reset]);

  const appendPage = useCallback((newPage: string) => {
    setPages((currentPages) => [...currentPages, newPage]);
    setSearchValue("");
  }, []);

  const popPage = useCallback(() => {
    setPages((currentPages) => {
      const x = [...currentPages];
      x.splice(-1, 1);
      return x;
    });
  }, []);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isHome || searchValue.length > 0) {
        return;
      }

      if (event.key === "Backspace") {
        event.preventDefault();
        popPage();
      }
    },
    [searchValue.length, isHome, popPage]
  );
  useEffect(() => {
    function down(event: KeyboardEvent): void {
      if (event.key === "Escape") {
        event.preventDefault();
        onClose();
        return;
      }
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setIsVisible(true);
      }
      onKeyDown(event);
    }

    document.addEventListener("keydown", down as any);
    return () => document.removeEventListener("keydown", down as any);
  }, [setIsVisible, onClose, onKeyDown]);

  if (!isVisible || !expression) {
    return null;
  }
  const flags = getFlagsFromTree({
    tree: expressionTree,
    skipTopLevels: 1,
  });
  console.debug("Command Flags", flags);

  return (
    <Modal
      style={{
        maxHeight: "532px",
        boxShadow:
          "0px 15px 45px 0px rgba(0, 0, 0, 0.10), 0px 0px 0px 3px rgba(122, 131, 150, 0.08)",
      }}
      childrenStyle={{ padding: 0 }}
      onClose={onClose}
    >
      <Command
        ref={ref}
        onKeyDown={onKeyDown}
        className="flex h-full w-[663px] flex-col items-stretch"
      >
        <div className="flex flex-row items-center gap-[11-px] border-b border-bd-darker px-4">
          <MagnifyingGlass
            color={lighterGreyHex}
            weight="regular"
            style={{ marginRight: 8 }}
          />
          <Command.Input
            autoFocus
            placeholder="Type a command or search…"
            value={searchValue}
            onValueChange={(value) => {
              setSearchValue(value);
            }}
            className="my-[17px] flex-grow bg-transparent text-tx-muted outline-none"
          />
          <Button text="Clear" onClick={reset} />
        </div>

        <Command.List className="h-full overflow-auto px-3 py-3">
          <Command.Empty className="pl-2 text-tx-muted">
            No results found.
          </Command.Empty>
          {(activePage === "flags" || searchValue !== "") && (
            <Flags flags={flags} onClose={onClose} />
          )}
          {project && (activePage === "analytics" || searchValue !== "") && (
            <Analytics views={project.analyticsViews} onClose={onClose} />
          )}
          {activePage === "home" && (
            <Home
              project={project}
              defaultFieldPath={defaultFieldPath}
              appendPage={appendPage}
              onClose={onClose}
              setSelectedView={setSelectedView}
            />
          )}
        </Command.List>
        <Footer />
      </Command>
    </Modal>
  );
}

function Home({
  project,
  defaultFieldPath,
  appendPage,
  onClose,
  setSelectedView,
}: {
  project?: ProjectQuery["project"];
  defaultFieldPath: string[];
  appendPage: (newPage: string) => void;
  onClose: () => void;
  setSelectedView: (newView: ProjectView) => void;
}): React.ReactElement {
  const dispatch = useAppDispatch();
  const { showIntercom } = useIntercom();

  const queryToken = getQueryToken(JSON.parse(project?.tokensJson || "{}"));

  return (
    <>
      {project && (
        <>
          <CommandGroup value="Flags">
            <Item
              icon={<MagnifyingGlass weight="bold" color={lighterGreyHex} />}
              value="Search flags"
              onSelect={() => appendPage("flags")}
            />
            <Item
              icon={<FlagIcon weight="fill" color={lighterGreyHex} />}
              value="Create new flag"
              onSelect={() => {
                setSelectedView("logic");
                dispatch(
                  setObjectAddFieldModalState({
                    objectTypeName: rootObjectTypeName,
                    fieldPosition: "first",
                    entity: {
                      name: "flag",
                      parentFieldPath:
                        defaultFieldPath.join(fieldPathSeparator),
                    },
                  })
                );
                onClose();
              }}
            />
            <Item
              icon={<Hash weight="bold" color={lighterGreyHex} />}
              value="Create new variable"
              onSelect={() => {
                setSelectedView("logic");
                dispatch(setNewVariableModalState({ defaultFieldPath }));
                onClose();
              }}
            />
            <Item
              icon={<ArrowsSplit weight="bold" color={lighterGreyHex} />}
              value="Create new test"
              onSelect={() => {
                setSelectedView("logic");
                dispatch(
                  setObjectAddFieldModalState({
                    objectTypeName: rootObjectTypeName,
                    fieldPosition: "first",
                    entity: {
                      name: "test",
                      parentFieldPath:
                        defaultFieldPath.join(fieldPathSeparator),
                    },
                  })
                );
                onClose();
              }}
            />
          </CommandGroup>
          <CommandGroup value="Analytics">
            <Item
              icon={<MagnifyingGlass weight="bold" color={lighterGreyHex} />}
              value="Search funnels"
              onSelect={() => appendPage("analytics")}
            />
            <Item
              icon={<Plus weight="bold" color={lighterGreyHex} />}
              value="Create new funnel"
              onSelect={() => {
                setSelectedView("analytics");
                dispatch(setShowNewViewModal(true));
                onClose();
              }}
            />
          </CommandGroup>
        </>
      )}

      <CommandGroup value="Help">
        {project && queryToken && (
          <Item
            icon={<ClipboardText color={lighterGreyHex} />}
            value="Copy main project token"
            onSelect={() => {
              navigator.clipboard
                .writeText(queryToken)
                .catch((error) =>
                  console.error(
                    "failed to copy project token to clipboard",
                    error
                  )
                );
              onClose();
            }}
          />
        )}
        <Item
          icon={<BookBookmark color={lighterGreyHex} />}
          value="Go to docs"
          onSelect={() => {
            window.open(docsUrl, "_blank", "noreferrer");
            onClose();
          }}
        />
        <Item
          icon={
            showIntercom.loading ? (
              <Spinner size={16} />
            ) : (
              <ChatTeardropText color={lighterGreyHex} />
            )
          }
          value="Open live chat"
          onSelect={async () => {
            await showIntercom();
            onClose();
          }}
        />
      </CommandGroup>
    </>
  );
}

function Flags({
  flags,
  onClose,
}: {
  flags: Flag[];
  onClose: () => void;
}): React.ReactElement {
  const setSelectedFieldPath = useLogicSetSelectedFieldPath({
    setLogicView: true,
  });

  return (
    <>
      {flags.map((flag) => {
        const label = stripRootPrefix(flag.path)
          .split(fieldPathSeparator)
          .slice(0, -1)
          .map((name) => toStartCase(name.replaceAll(variablePathSuffix, "")))
          .concat([flag.label])
          .join(" / ");

        return (
          <Item
            icon={
              <ValueTypeConstraintIcon
                isVariable={flag.isVariable}
                hasChildren={flag.hasChildren}
                valueTypeConstraint={flag.valueTypeConstraint}
              />
            }
            value={label}
            context={["flags"]}
            onSelect={() => {
              setSelectedFieldPath(flag.path);
              onClose();
            }}
          />
        );
      })}
    </>
  );
}

function Analytics({
  views,
  onClose,
}: {
  views: ProjectQuery["project"]["analyticsViews"];
  onClose: () => void;
}): React.ReactElement {
  const [, setSelectedAnalyticsViewId] = useSelectedAnalyticsViewId();

  return (
    <>
      {views.map((view) => {
        return (
          <Item
            icon={<ChartBar />}
            value={view.name}
            context={["analytics"]}
            onSelect={() => {
              setSelectedAnalyticsViewId(view.id);
              onClose();
            }}
          />
        );
      })}
    </>
  );
}

function CommandGroup({
  heading,
  value,
  children,
}: {
  heading?: string;
  value?: string;
  children?: React.ReactNode;
}): React.ReactElement | null {
  return (
    <>
      <Command.Group
        className="command-group text-tx-muted"
        heading={heading}
        value={value}
      >
        {children}
      </Command.Group>
      <Command.Separator className="my-3 border-b border-bd-darker last:hidden" />
    </>
  );
}

function Item({
  value,
  context,
  disabled,
  icon,
  onSelect,
}: {
  value: string;
  context?: string[];
  disabled?: boolean;
  icon?: React.ReactNode;
  onSelect?: (newValue: string) => void;
}): React.ReactElement {
  return (
    <Command.Item
      disabled={disabled}
      value={(context ? `${context.join(" ")} ` : "") + value}
      onSelect={
        onSelect ||
        (() => {
          // Dummy
        })
      }
      className="group flex cursor-pointer select-none flex-row items-center justify-between
                 rounded-xl p-4 font-medium leading-normal text-tx-default
               aria-disabled:cursor-default
               aria-disabled:text-tx-disabled aria-selected:bg-base-grey-1-medium/50"
    >
      <div className="flex flex-grow flex-row items-center gap-2">
        {icon || null}
        {value}
        {disabled && <div className="flex-grow text-right">Coming soon!</div>}
      </div>

      <div className="hidden group-aria-selected:block">
        <ArrowBendDownLeft weight="regular" color={lighterGreyHex} />
      </div>
    </Command.Item>
  );
}

function Footer(): React.ReactElement {
  return (
    <div
      className="flex flex-row items-center justify-between border-t border-bd-darker 
                  px-[26px] py-[13px] text-tx-muted"
    >
      <div className="flex flex-row gap-7">
        <ShortcutDefinition
          keys={[
            <ArrowUp size={12} weight="regular" color={lighterGreyHex} />,
            <ArrowDown size={12} weight="regular" color={lighterGreyHex} />,
          ]}
          textRight="Move"
        />
        <ShortcutDefinition
          keys={[
            <ArrowBendDownLeft
              size={12}
              weight="regular"
              color={lighterGreyHex}
            />,
          ]}
          textRight="Enter"
        />
        <ShortcutDefinition
          keys={[
            <Backspace size={12} weight="regular" color={lighterGreyHex} />,
          ]}
          textRight="Back"
        />
      </div>
      <ShortcutDefinition keys={[<p>ESC</p>]} textLeft="Close" />
    </div>
  );
}
