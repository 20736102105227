import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  fieldPathSeparator,
  Expression,
  Schema,
  mapExpressionWithResult,
} from "@hypertune/sdk/src/shared";
import { NavigateOptions } from "react-router-dom";
import { FunnelSimple, Plus } from "@phosphor-icons/react";
import {
  queryObjectTypeName,
  rootFieldName,
  variablePathSuffix,
} from "@hypertune/shared-internal";
import { ValueTypeConstraint } from "@hypertune/shared-internal/src/expression/types";
import { bottomScrollSpacing } from "../../../../lib/constants";
import { ExpressionControlContext } from "../../../../lib/types";
import SidebarItem from "../../../../components/SidebarItem";
import ExpressionTree from "./ExpressionTree";
import ValueTypeConstraintIcon from "./ValueTypeConstraintIcon";

import Folder from "../../../../components/icons/Folder";
import matchesSearch from "../../../../lib/generic/matchesSearch";
import {
  ExpressionNode,
  ExpressionNodeMap,
  SelectedExpressionNode,
  findItemInTree,
} from "./toTree";
import { iconColor } from "../../../../components/icons/icons";
import ExpressionDetail from "./ExpressionDetail";
import SidebarContainer from "../../SidebarContainer";
import NewFlagButton, {
  useShowNewFlagModal,
} from "../../controls/NewFlagButton";
import ExpressionViewTopBar from "./ExpressionViewTopBar";
import {
  useLogicSelectedFieldPath,
  useLogicSetSelectedFieldPath,
} from "../../logicHooks";

import getDefaultFieldPathAndTopLevelEnumTypeName from "../../../../lib/expression/getDefaultFieldPathAndTopLevelEnumTypeName";
import Button from "../../../../components/buttons/Button";

import getListExpressionItemsAndSetItemsFunction from "../../../../lib/expression/getListExpressionItemsAndSetItemsFunction";
import useSearchParamsState from "../../../../app/useSearchParamsState";
import TopBarDropdown, {
  LabeledOption,
} from "../../../../components/TopBarDropdown";
import getLabeledOption from "../../../../lib/getLabeledOption";
import Flags3 from "../../../../components/icons/Flags3";
import SortableList from "../../../../components/SortableList";

export const viewCompleteTreeSuffix = `${fieldPathSeparator}--view--all`;

const searchTextQueryParamKey = "logic_search";

const valueTypeConstraint: ValueTypeConstraint = {
  type: "ObjectValueTypeConstraint",
  objectTypeName: queryObjectTypeName,
};

export default function ExpressionView({
  isVisible,
  hasError,
  context,
  schema,
  expressionTree,
  expression,
  setExpression,
  defaultFieldPath,
}: {
  isVisible: boolean;
  hasError: boolean;
  context: ExpressionControlContext;
  schema: Schema;
  expressionTree: ExpressionNodeMap;
  expression: Expression;
  setExpression: (newExpression: Expression | null) => void;
  defaultFieldPath: string[];
}): React.ReactElement {
  const { readOnly } = context;
  const showNewFlagModal = useShowNewFlagModal(defaultFieldPath);
  const contentDivRef = useRef<HTMLDivElement>(null);

  const [searchText, setSearchText] = useSearchParamsState(
    searchTextQueryParamKey,
    ""
  );
  const [searchedLabels, setSearchedLabels] = useSearchParamsState(
    "tags_search",
    ""
  );

  const expressionStringsMap = useMemo(() => {
    const stringsMap = {};
    collectExpressionStrings(stringsMap, expressionTree[rootFieldName]);

    return stringsMap;
  }, [expressionTree]);

  const defaultFieldPathString = defaultFieldPath.join(fieldPathSeparator);
  const viewCompleteTreePath = defaultFieldPathString + viewCompleteTreeSuffix;

  const selectedFieldPath = useLogicSelectedFieldPath(defaultFieldPathString);
  // eslint-disable-next-line no-underscore-dangle
  const _setSelectedFieldPath = useLogicSetSelectedFieldPath();
  const setSelectedFieldPath = useCallback(
    (newSelectedFieldPath: string, options?: NavigateOptions) => {
      if (selectedFieldPath !== newSelectedFieldPath) {
        const el = contentDivRef.current;
        if (el) {
          el.scrollTo({ top: 0 });
        }
      }
      _setSelectedFieldPath(newSelectedFieldPath, options);
    },
    [_setSelectedFieldPath, contentDivRef, selectedFieldPath]
  );

  console.debug("Schema", context.commitContext.schema);
  console.debug("Tree", expressionTree);

  const isFullTreeViewSelected = selectedFieldPath?.endsWith(
    viewCompleteTreeSuffix
  );

  const selected = useMemo<SelectedExpressionNode>(
    () =>
      isFullTreeViewSelected
        ? {
            item: {
              context,
              fieldLabel: "",
              fullFieldPath: "",
              index: null,
              hasActions: false,
              hasError,
              title: "",
              search: "",
              childExpressions: expressionTree,
              variables: {},
              setVariableName: {},
              valueTypeConstraint,
              expression,
              setExpression,
              lift: () => {
                // Dummy
              },
              includeExpressionOption: () => true,
            },
            parent: null,
          }
        : findItemInTree(
            expressionTree,
            null,
            selectedFieldPath?.split(fieldPathSeparator) ?? []
          ),
    [
      isFullTreeViewSelected,
      context,
      hasError,
      expressionTree,
      expression,
      setExpression,
      selectedFieldPath,
    ]
  );
  console.debug("Selected", selected);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    if (
      selected.item &&
      selected.item.fullFieldPath !== defaultFieldPathString
    ) {
      document.title = `${selected.item.fieldLabel} - Hypertune`;
      return;
    }
    document.title = "Logic - Hypertune";
  }, [defaultFieldPathString, isVisible, selected]);
  useEffect(() => {
    if (!selectedFieldPath) {
      return;
    }
    if (selectedFieldPath.endsWith(viewCompleteTreeSuffix)) {
      if (selectedFieldPath !== viewCompleteTreePath) {
        // Correct view complete tree path.
        setSelectedFieldPath(viewCompleteTreePath);
      }
      return;
    }
    if (!selected.item) {
      if (
        findItemInTree(expressionTree, null, defaultFieldPath).item === null
      ) {
        // If default field is not in the tree then reset it based on current expression.
        const { defaultFieldPath: newDefaultFieldPath } =
          getDefaultFieldPathAndTopLevelEnumTypeName(schema, expression);

        setSelectedFieldPath(newDefaultFieldPath.join(fieldPathSeparator));
      }
    }
    // We don't want changes to selectedField to trigger this function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const searchedLabelsSet = useMemo(() => {
    return new Set(searchedLabels ? searchedLabels.split("&&") : "");
  }, [searchedLabels]);

  const schemaTags: LabeledOption<string>[] = useMemo(() => {
    return Object.values(schema.tags ?? {}).map((tag) => {
      return getLabeledOption(
        tag.name,
        tag.color,
        true,
        searchedLabelsSet.has(tag.name)
      );
    });
  }, [schema.tags, searchedLabelsSet]);

  const setLabels = useCallback(
    (newLabelSet: Set<string>) => {
      const updatedSearchParam = Array.from(newLabelSet).join("&&");
      setSearchedLabels(updatedSearchParam);
    },
    [setSearchedLabels]
  );

  useEffect(() => {
    try {
      const validTags = new Set(Object.keys(schema?.tags ?? []));
      const newSearchedLabelsSet = new Set(
        Array.from(searchedLabelsSet).filter((tag) => validTags.has(tag))
      );

      if (searchedLabelsSet.size !== newSearchedLabelsSet.size) {
        setLabels(newSearchedLabelsSet);
      }
    } catch (error) {
      console.error("failed to construct new labels set", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema.tags]);

  return (
    <div
      style={{
        flexGrow: 1,
        display: isVisible ? "flex" : "none",
        flexDirection: "row",
        alignItems: "stretch",
        height: "100%",
        width: "100%",
      }}
      className="bg-bg-light bg-dotted"
    >
      <SidebarContainer
        searchText={searchText}
        setSearchText={setSearchText}
        actions={
          <div className="flex flex-row gap-2">
            <TopBarDropdown
              value={{
                label: "",
                value: "add-label-primary",
                icon: (
                  <div
                    className={`flex items-center ${searchedLabels ? "gap-[5px]" : ""}`}
                  >
                    <FunnelSimple
                      className="h-4 w-4 text-tx-muted"
                      weight="regular"
                    />{" "}
                    <span className="text-base text-tx-muted">
                      {searchedLabelsSet.size > 0 ? searchedLabelsSet.size : ""}
                    </span>
                  </div>
                ),
                showIconWhenSelected: true,
                isSelected: true,
              }}
              searchInputPlaceholder="Filter labels..."
              placeholder=""
              options={{ type: "options", options: schemaTags }}
              onChange={(selectedLabel) => {
                if (!selectedLabel) {
                  return;
                }
                const updatedSet = new Set(searchedLabelsSet);
                if (updatedSet.has(selectedLabel.value)) {
                  updatedSet.delete(selectedLabel.value);
                  setLabels(updatedSet);
                  return;
                }
                setLabels(updatedSet.add(selectedLabel.value));
              }}
              dropdownStyle={{
                hideSearch: false,
                buttonClassName: `px-[7px] leading-none font-medium disableBgWhenSelected text-tx-muted`,
                panelClassName: "pt-1 data-top:pb-1",
                muted: "all",
                caret: null,
              }}
              multiSelect
            />
            {!readOnly && <NewFlagButton defaultFieldPath={defaultFieldPath} />}
          </div>
        }
      >
        <ExpressionNodeListRows
          readOnly={readOnly}
          expressionStringsMap={expressionStringsMap}
          parentExpressionNode={null}
          tree={
            findItemInTree(expressionTree, null, defaultFieldPath)?.item
              ?.childExpressions || {}
          }
          searchText={searchText}
          selectedFieldPath={selectedFieldPath || ""}
          setSelectedFieldPath={setSelectedFieldPath}
          searchedLabel={searchedLabels}
        />
        {!searchText && !searchedLabels && (
          <SidebarItem
            icon={<Folder />}
            title="View complete tree"
            className="px-3 py-[11px]"
            onClick={() => {
              setSelectedFieldPath(viewCompleteTreePath);
            }}
            hasError={hasError}
            isSelected={isFullTreeViewSelected}
          />
        )}
      </SidebarContainer>
      {selectedFieldPath &&
      selected.item &&
      selected.item.fullFieldPath !== defaultFieldPathString ? (
        <div className="flex h-full w-full flex-col overflow-hidden">
          {!isFullTreeViewSelected && (
            <ExpressionViewTopBar
              readOnly={readOnly}
              schema={schema}
              selectedExpressionNode={selected.item}
              selectedExpressionNodeParent={
                selected.parent ||
                findItemInTree(expressionTree, null, defaultFieldPath)?.item
              }
              selectedFieldPath={selectedFieldPath}
              setSelectedFieldPath={setSelectedFieldPath}
            />
          )}
          <div className="flex h-full w-full flex-row justify-between overflow-hidden">
            <div
              ref={contentDivRef}
              style={{
                flexGrow: 1,
                overflow: "auto",
                paddingBottom: bottomScrollSpacing,
              }}
            >
              <ExpressionTree
                context={{
                  ...context,
                  ...selected.item.context,
                  disableVariableLift:
                    !!selected.item.variableContext &&
                    selected.parent?.fullFieldPath === defaultFieldPathString,
                  fullFieldPath: isFullTreeViewSelected
                    ? ""
                    : selectedFieldPath,
                }}
                variables={selected.item.variables}
                setVariableName={selected.item.setVariableName}
                valueTypeConstraint={selected.item.valueTypeConstraint}
                expression={selected.item.expression}
                setExpression={selected.item.setExpression}
                parentExpression={selected.parent?.expression || null}
                setParentExpression={selected.parent?.setExpression}
                lift={({
                  argument,
                  replacedVariableIdToNewVariable,
                  replaceArgument,
                  newVariableName,
                  isNew,
                  keepInObjectField,
                }) => {
                  if (!selected.item) {
                    return;
                  }
                  selected.item.lift({
                    argument,
                    replacedVariableIdToNewVariable,
                    replaceArgument,
                    newVariableName,
                    isNew,
                    keepInObjectField,
                  });
                  if (selectedFieldPath && newVariableName) {
                    const newPath = selectedFieldPath.split(fieldPathSeparator);

                    if (
                      selected.parent?.expression?.valueType.type ===
                        "FunctionValueType" &&
                      (selected.item.expression?.type ===
                        "FunctionExpression" ||
                        selected.item.expression?.type ===
                          "ApplicationExpression")
                    ) {
                      // Don't shorten the path when extracting variables inside of a nested object.
                      newPath[newPath.length - 1] =
                        newVariableName + variablePathSuffix;
                    } else if (isNew) {
                      newPath[newPath.length - 1] =
                        newVariableName + variablePathSuffix;
                    } else if (newPath.length > 1) {
                      newPath.splice(newPath.length - 2, 1);
                    }

                    setSelectedFieldPath(newPath.join(fieldPathSeparator));
                  }
                }}
                variableContext={
                  selected.item.variableContext
                    ? {
                        name: selected.item.variableContext.name,
                        rename: selected.item.variableContext.rename,
                        drop: () => {
                          selected.item?.variableContext?.drop();
                          setSelectedFieldPath(defaultFieldPathString);
                        },
                      }
                    : undefined
                }
                includeExpressionOption={selected.item.includeExpressionOption}
              />
            </div>
            {!isFullTreeViewSelected && (
              <ExpressionDetail
                readOnly={readOnly}
                parentExpression={selected.parent?.expression ?? null}
                expression={selected.item.expression}
                fieldName={selected.item.fieldLabel}
                variables={selected.item.variables}
                variableContext={selected.item.variableContext}
                lift={selected.item.lift}
                schema={context.commitContext.schema}
                topLevelExpression={expression}
                onDelete={() => setSelectedFieldPath(defaultFieldPathString)}
                setTopLevelExpression={setExpression}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-center bg-bg-light">
          <Flags3 />
          <h3 className="text-h3 font-semibold tracking-[-0.6px]">
            No flag selected
          </h3>
          <h5 className="text-h5 leading-9 tracking-[-0.32px] text-tx-muted">
            Select a flag in the left sidebar
          </h5>
          {!readOnly && showNewFlagModal && (
            <Button
              text="Create new flag"
              size="large"
              weight="filled"
              intent="primary"
              className="mt-[23px]"
              onClick={showNewFlagModal}
            />
          )}
        </div>
      )}
    </div>
  );
}

function ExpressionNodeListRows({
  readOnly,
  expressionStringsMap,
  parentExpressionNode,
  tree,
  searchText,
  selectedFieldPath,
  setSelectedFieldPath,
  searchedLabel,
}: {
  readOnly: boolean;
  expressionStringsMap: Record<string, string[]>;
  parentExpressionNode: ExpressionNode | null;
  tree: ExpressionNodeMap;
  searchText: string;
  selectedFieldPath: string | null;
  setSelectedFieldPath: (newSelectedFieldPath: string) => void;
  searchedLabel: string;
}): React.ReactElement | null {
  const allChildNodes = Object.values(tree);
  const childNodes = allChildNodes.filter(
    (item) =>
      matchesTreeSearch(expressionStringsMap, searchText, item) &&
      (matchesLabelTreeSearch(searchedLabel.split("&&"), item) ||
        !searchedLabel)
  );
  const variableChildNodes = childNodes.filter(
    (item) => !!item.variableContext
  );
  const allBaseChildNodes = allChildNodes.filter(
    (item) => !item.variableContext
  );
  const baseChildNodes = childNodes.filter((item) => !item.variableContext);
  const isListFiltered = allBaseChildNodes.length !== baseChildNodes.length;

  const { items, setItems } = getListExpressionItemsAndSetItemsFunction(
    parentExpressionNode?.expression ?? null
  );

  return (
    <>
      {variableChildNodes.map((item) => {
        return (
          <ExpressionNodeRow
            key={`expression-node-variable-row-${item.expression?.id}`}
            readOnly={readOnly}
            expressionStringsMap={expressionStringsMap}
            expressionNode={item}
            searchText={searchText}
            selectedFieldPath={selectedFieldPath}
            setSelectedFieldPath={setSelectedFieldPath}
            searchedLabel={searchedLabel}
          />
        );
      })}
      {items &&
      !isListFiltered &&
      !baseChildNodes.some(({ index }) => index === null) ? (
        <SortableList
          list={items as Expression[]}
          setList={(newItems) =>
            parentExpressionNode?.setExpression(setItems(newItems))
          }
          disabled={readOnly || items.length === 1}
          renderItemComponent={({ id, index, dragHandle }) => (
            <ExpressionNodeRow
              key={`expression-node-row-${id}`}
              readOnly={readOnly}
              expressionStringsMap={expressionStringsMap}
              expressionNode={allBaseChildNodes[index]}
              searchText={searchText}
              selectedFieldPath={selectedFieldPath}
              setSelectedFieldPath={setSelectedFieldPath}
              searchedLabel={searchedLabel}
              dragHandle={dragHandle}
            />
          )}
        />
      ) : (
        <>
          {baseChildNodes.map((item) => {
            return (
              <ExpressionNodeRow
                key={`expression-node-row-${item.expression?.id}`}
                readOnly={readOnly}
                expressionStringsMap={expressionStringsMap}
                expressionNode={item}
                searchText={searchText}
                selectedFieldPath={selectedFieldPath}
                setSelectedFieldPath={setSelectedFieldPath}
                searchedLabel={searchedLabel}
              />
            );
          })}
        </>
      )}
    </>
  );
}

function ExpressionNodeRow({
  readOnly,
  expressionStringsMap,
  expressionNode,
  searchText,
  selectedFieldPath,
  setSelectedFieldPath,
  searchedLabel,
  dragHandle,
}: {
  readOnly: boolean;
  expressionStringsMap: Record<string, string[]>;
  expressionNode: ExpressionNode;
  searchText: string;
  selectedFieldPath: string | null;
  setSelectedFieldPath: (newSelectedFieldPath: string) => void;
  searchedLabel: string;
  dragHandle?: React.ReactNode;
}): React.ReactElement {
  const newChild = expressionNode.newChildExpression;
  const showNewChild = newChild && !readOnly;
  const hasChildren = expressionNode.childExpressions !== null;
  const isVariable = !!expressionNode.variableContext;
  const isSelected = selectedFieldPath === expressionNode.fullFieldPath;

  return (
    <SidebarItem
      icon={
        !readOnly && dragHandle ? (
          <div className="-ml-1 flex flex-row items-center gap-1">
            {dragHandle}
            <ValueTypeConstraintIcon
              isVariable={isVariable}
              hasChildren={hasChildren}
              valueTypeConstraint={expressionNode.valueTypeConstraint}
            />
          </div>
        ) : (
          <ValueTypeConstraintIcon
            isVariable={isVariable}
            hasChildren={hasChildren}
            valueTypeConstraint={expressionNode.valueTypeConstraint}
          />
        )
      }
      title={expressionNode.fieldLabel}
      className={`px-3 ${hasChildren || showNewChild ? "py-[9px]" : "py-[11px]"}`}
      onClick={() => setSelectedFieldPath(expressionNode.fullFieldPath)}
      isSelected={isSelected}
      hasError={expressionNode.hasError}
      actions={[
        ...(showNewChild
          ? [
              <Button
                intent={isSelected ? "primary" : "neutral"}
                weight="outlined"
                size="x-small"
                icon={
                  <Plus
                    weight="bold"
                    size={12}
                    color={iconColor(!!isSelected)}
                  />
                }
                onClick={() => {
                  const newChildId = newChild();
                  if (newChildId) {
                    setSelectedFieldPath(
                      `${expressionNode.fullFieldPath}${fieldPathSeparator}${newChildId}`
                    );
                  }
                }}
              />,
            ]
          : []),
      ]}
      showCollapseToggle={!!newChild}
    >
      {expressionNode.childExpressions ? (
        <ExpressionNodeListRows
          readOnly={readOnly}
          expressionStringsMap={expressionStringsMap}
          parentExpressionNode={expressionNode}
          tree={expressionNode.childExpressions}
          searchText={searchText}
          selectedFieldPath={selectedFieldPath}
          setSelectedFieldPath={setSelectedFieldPath}
          searchedLabel={searchedLabel}
        />
      ) : null}
    </SidebarItem>
  );
}

function matchesTreeSearch(
  stringsMap: { [fullFieldPath: string]: string[] },
  searchText: string,
  item: ExpressionNode
): boolean {
  return (
    matchesSearch(searchText, [
      item.fullFieldPath,
      item.fieldLabel,
      ...(stringsMap[item.fullFieldPath] ?? []),
    ]) ||
    (!!item.childExpressions &&
      Object.entries(item.childExpressions).some(([, childItem]) => {
        return matchesTreeSearch(stringsMap, searchText, childItem);
      }, false))
  );
}

function matchesLabelTreeSearch(
  searchedLabel: string[],
  item: ExpressionNode
): boolean {
  return (
    (item.expression?.metadata?.tags &&
      searchedLabel.every((tag) => tag in item.expression!.metadata!.tags!)) ||
    (!!item.childExpressions &&
      Object.entries(item.childExpressions).some(([, childItem]) => {
        return matchesLabelTreeSearch(searchedLabel, childItem);
      }, false))
  );
}

function collectExpressionStrings(
  stringsMap: { [fullFieldPath: string]: string[] },
  item: ExpressionNode
): void {
  if (item.childExpressions) {
    Object.values(item.childExpressions).forEach((childItem) => {
      collectExpressionStrings(stringsMap, childItem);
    });
    stringsMap[item.fullFieldPath] = Object.values(
      item.childExpressions
    ).flatMap((childItem) => stringsMap[childItem.fullFieldPath] ?? []);
    return;
  }
  stringsMap[item.fullFieldPath] = mapExpressionWithResult<string[]>(
    (expr) => {
      if (
        expr?.type === "StringExpression" ||
        expr?.type === "EnumExpression"
      ) {
        return { newExpression: expr, mapResult: [expr.value] };
      }
      return { newExpression: expr, mapResult: [] };
    },
    (...results: string[][]) => results.flat(),
    item.expression
  ).mapResult;
}
