import {
  EventTypeMap,
  Expression,
  Schema,
  Split,
  SplitMap,
  nullThrows,
} from "@hypertune/sdk/src/shared";
import reconcileSchemaAndEventTypeMap from "./reconcileSchemaAndEventTypeMap";
import fixAndSimplify from "./expression/fixAndSimplify";
import getSchemaCodeFromSchema from "./schema/getSchemaCodeFromSchema";
import { getSplitGoalEventTypeNameFromRewardSql } from "./splitRewardSql";
import getStringValuePathsForObject from "./schema/getStringValuePathsForObject";

export default function reconcileSchemaAndImplementation(
  schema: Schema,
  splits: SplitMap,
  eventTypeMap: EventTypeMap,
  expression: Expression
): {
  newSchema: Schema;
  newSchemaCode: string;
  newEventTypeMap: EventTypeMap;
  newSplits: SplitMap;
  newExpression: Expression;
} {
  const { newSchema, newEventTypeMap } = reconcileSchemaAndEventTypeMap(
    schema,
    eventTypeMap
  );

  const newSplits = reconcileSplits(newSchema, splits);

  const { newExpression } = fixAndSimplify(
    newSchema,
    newSplits,
    newEventTypeMap,
    null,
    expression
  );
  return {
    newSchema,
    newSchemaCode: getSchemaCodeFromSchema(newSchema),
    newEventTypeMap,
    newSplits,
    newExpression,
  };
}

function reconcileSplits(schema: Schema, splits: SplitMap): SplitMap {
  return Object.fromEntries(
    Object.entries(splits).map<[string, Split]>(([id, split]) => {
      // Populate all fields explicitly so that we remove extra fields.
      let newSplit: Split;

      if (split.type === "test") {
        newSplit = {
          type: "test",
          id: split.id,
          name: split.name,
          description: split.description,
          dimensions: split.dimensions,
          eventObjectTypeName: split.eventObjectTypeName ?? null,
          featureIds: {},
        };
      } else {
        const goalEventObjectTypeName = nullThrows(
          getSplitGoalEventTypeNameFromRewardSql(split.rewardSql),
          new Error(`missing eventObjectTypeName in split ${split.id}}`)
        );

        newSplit = {
          type: "ml",
          id: split.id,
          name: split.name,
          description: split.description,
          dimensions: split.dimensions,
          eventObjectTypeName: split.eventObjectTypeName ?? null,
          rewardSql: split.rewardSql,
          rewardEvents: split.rewardEvents || [
            {
              eventObjectTypeName: goalEventObjectTypeName,
              unitIdPayloadPath: getStringValuePathsForObject(
                schema,
                goalEventObjectTypeName
              )[0],
            },
          ],
          featureIds: {},
        };
      }

      return [id, newSplit];
    })
  );
}
