import {
  Schema,
  SplitMap,
  fieldPathSeparator,
} from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import React, { useMemo } from "react";
import { getStringValuePathsForObject } from "@hypertune/shared-internal";
import { twMerge } from "tailwind-merge";
import { Intent } from "../../components/intent";
import TopBarDropdown from "../../components/TopBarDropdown";

export default function UnitIdPathSelector({
  context,
  source,
  eventTypeName,
  splitId,
  unitIdPath,
  setUnitIdPath,
  readOnly,
  intent = "neutral",
  buttonClassName,
}: {
  context: { splits: SplitMap; schema: Schema };
  source: "split" | "event";
  eventTypeName?: string | null;
  splitId?: string | null;
  unitIdPath: string | null;
  setUnitIdPath: (newUnitIdPath: string) => void;
  readOnly?: boolean;
  intent?: Intent;
  buttonClassName?: string;
}): React.ReactElement {
  const unitIdOptions = useMemo(() => {
    return (
      source === "event" && eventTypeName
        ? getJoinIdOptionsForObject(context.schema, eventTypeName)
        : source === "split" && splitId
          ? context.splits[splitId]?.eventObjectTypeName
            ? [
                ["unitId"],
                ...getJoinIdOptionsForObject(
                  context.schema,
                  context.splits[splitId].eventObjectTypeName as string
                ),
              ]
            : [["unitId"]]
          : []
    ).map((path) => {
      return {
        value: path.join(fieldPathSeparator),
        label: path.map(toStartCase).join(fieldPathSeparator),
      };
    });
  }, [context.schema, context.splits, source, eventTypeName, splitId]);

  const isReadOnly =
    readOnly || unitIdOptions.length === 0 || source === "split";

  const value = unitIdPath
    ? (unitIdOptions.find((option) => option.value === unitIdPath) ?? null)
    : null;
  const intentWithError =
    value === null && intent === "neutral" && unitIdOptions.length > 0
      ? "danger"
      : intent;

  return (
    <TopBarDropdown
      intent={intentWithError}
      options={{
        type: "options",
        options: unitIdOptions,
      }}
      readOnly={isReadOnly}
      value={
        unitIdPath
          ? (unitIdOptions.find((option) => option.value === unitIdPath) ??
            null)
          : null
      }
      onChange={(newJoinIdPath) => setUnitIdPath(newJoinIdPath?.value || "")}
      placeholder={
        unitIdOptions.length === 0
          ? `Select ${source === "event" ? "event type" : "split"} first`
          : "Select unit ID..."
      }
      dropdownStyle={{
        minWidth: 0,
        scrollToPosition: "center",
        buttonClassName: twMerge(
          "border font-medium rounded-lg h-[30px] px-2",
          intentWithError === "neutral" && !isReadOnly
            ? "bg-white hover:border-intent-primary group-hover:border-intent-primary hover:bg-white focus:border-intent-primary focus:shadow-inputs-sm"
            : "",
          intentWithError === "danger"
            ? "hover:border-intent-danger focus:border-intent-danger hover:bg-intent-danger/10"
            : "",
          isReadOnly ? "bg-bg-light" : "",
          buttonClassName
        ),
        openButtonClassName:
          intentWithError === "neutral"
            ? "border-intent-primary shadow-inputs-sm"
            : intentWithError === "danger"
              ? "border-intent-danger shadow-inputs-sm-error focus:shadow-inputs-sm-error"
              : "",
        optionClassName: "font-medium",
        panelClassName: "pt-1 data-top:pb-1",
        caret: "downLarge",
      }}
    />
  );
}

export function getJoinIdOptionsForObject(
  schema: Schema,
  objectTypeName: string
): string[][] {
  return getStringValuePathsForObject(schema, objectTypeName).map((path) => [
    "payload",
    ...path,
  ]);
}
