import { Expression } from "@hypertune/sdk/src/shared/types";
import { rootObjectTypeName } from "@hypertune/shared-internal/src/constants";

export default function isRootObjectExpression(
  expression: Expression
): boolean {
  return (
    expression &&
    expression.type === "ObjectExpression" &&
    expression.valueType.type === "ObjectValueType" &&
    expression.valueType.objectTypeName === rootObjectTypeName
  );
}
