import {
  FunctionExpression,
  ValueType,
  uniqueId,
  Expression,
  getBooleanExpression,
  getStringExpression,
  getIntExpression,
  getFloatExpression,
  Schema,
} from "@hypertune/sdk/src/shared";
import getParameters from "./getParameters";
import getDefaultExpression from "./getDefaultExpression";
import getConstraintFromValueType from "./constraint/getConstraintFromValueType";
import getLogEventExpression from "./getLogEventExpression";
import { VariableMap } from "./types";
import getContextExpression from "./getContextExpression";
import { getDefaultUnitIdExpression } from "./getExpressionOptionGroups";

export type DefaultValues = {
  boolean?: boolean;
  string?: string;
  int?: number;
  float?: number;
  eventTypeNameAndId?: {
    id: string;
    name: string;
    payload?: {
      contextTypeName: string;
      rootArgsVariableId: string;
    };
  };
  split?: {
    id: string;
    dimensionId: string;
    variables: VariableMap;
  };

  complexTypes?: boolean;
};

export default function getEmptyExpression(
  schema: Schema,
  variables: VariableMap,
  valueType: ValueType,
  defaultValues: DefaultValues | null
): null | FunctionExpression {
  if (valueType.type !== "FunctionValueType") {
    return null;
  }

  const parameters = getParameters(variables, valueType.parameterValueTypes);
  const functionExpression: FunctionExpression = {
    id: uniqueId(),
    type: "FunctionExpression",
    valueType,
    parameters,
    body: null,
  };
  if (defaultValues?.split) {
    functionExpression.body = {
      id: uniqueId(),
      type: "SplitExpression",
      valueType: valueType.returnValueType,
      splitId: defaultValues.split.id,
      dimensionId: defaultValues.split.dimensionId,
      expose: getBooleanExpression(true),
      unitId: getDefaultUnitIdExpression(schema, defaultValues.split.variables),
      dimensionMapping: {
        type: "discrete",
        cases: {},
      },
      eventObjectTypeName: null,
      eventPayload: null,
      featuresMapping: {},
    };
    return functionExpression;
  }

  if (defaultValues) {
    switch (valueType.returnValueType.type) {
      case "BooleanValueType":
        if (defaultValues.boolean !== undefined) {
          functionExpression.body = getBooleanExpression(defaultValues.boolean);
        }
        break;
      case "StringValueType":
        if (defaultValues.string !== undefined) {
          functionExpression.body = getStringExpression(defaultValues.string);
        }
        break;
      case "IntValueType":
        if (defaultValues.int !== undefined) {
          functionExpression.body = getIntExpression(defaultValues.int);
        }
        break;
      case "FloatValueType":
        if (defaultValues.float !== undefined) {
          functionExpression.body = getFloatExpression(defaultValues.float);
        }
        break;
      case "VoidValueType":
        if (defaultValues.eventTypeNameAndId) {
          const { name, id, payload } = defaultValues.eventTypeNameAndId;
          functionExpression.body = getLogEventExpression(name, id);
          functionExpression.body.unitId = getStringExpression("");
          if (payload) {
            functionExpression.body.eventPayload = {
              id: uniqueId(),
              type: "ObjectExpression",
              valueType: { type: "ObjectValueType", objectTypeName: name },
              objectTypeName: name,
              fields: {
                context: getContextExpression(
                  payload.contextTypeName,
                  payload.rootArgsVariableId
                ),
              },
            };
          }
        }
        break;
      case "EnumValueType":
        if (defaultValues.complexTypes) {
          const enumExpression = getDefaultExpression(
            schema,
            variables,
            getConstraintFromValueType(valueType.returnValueType),
            new Set<string>()
          ) as Expression;

          functionExpression.body = enumExpression;
        }
        break;

      case "ObjectValueType":
        if (defaultValues.complexTypes) {
          functionExpression.body = getDefaultExpression(
            schema,
            variables,
            getConstraintFromValueType(valueType.returnValueType),
            new Set<string>()
          );
        }
        break;
      case "ListValueType":
        if (defaultValues.complexTypes) {
          functionExpression.body = getDefaultExpression(
            schema,
            variables,
            getConstraintFromValueType(valueType.returnValueType),
            new Set<string>()
          );
        }
        break;

      default:
    }
  }
  return functionExpression;
}
