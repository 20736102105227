import React, { useState } from "react";
import { Expression } from "@hypertune/sdk/src/shared";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import getExpressionOptionGroups from "@hypertune/shared-internal/src/expression/getExpressionOptionGroups";
import {
  expressionsAreEqual,
  variableExpressionOptionsGroupLabel,
} from "@hypertune/shared-internal";
import getExpressionOptionLabel from "../../../../lib/expression/getExpressionOptionLabel";
import {
  normal,
  optionsButtonRight,
  optionsButtonTop,
} from "../../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
} from "../../../../lib/types";
import setSelectedExpressionId from "../../../../lib/expression/setSelectedExpressionId";
import getExpressionOptionIcon from "../../../../lib/expression/getExpressionOptionIcon";
import GoToArrowButton from "../../../../components/buttons/GoToArrowButton";
import isReadOnly from "../../../../lib/expression/isReadOnly";
import ExpressionControl from "./ExpressionControl";
import {
  getVariableGroupIndexClassName,
  VariableValuePreview,
} from "./VariableNameControl";
import { useHypertune } from "../../../../generated/hypertune.react";
import TopBarDropdown, {
  LabeledOptionGroup,
} from "../../../../components/TopBarDropdown";
import twMerge from "../../../../lib/twMerge";

export default function InsertExpressionControl({
  context,
  variables,
  parentExpression,
  valueTypeConstraint,
  setExpression,
  includeExpressionOption,
  shouldStack,
  allowEmpty,
  focusOnMount,
  selected,
  optionsButton,
  disablePanelOnSelect,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  parentExpression: Expression | null;
  valueTypeConstraint: ValueTypeConstraint;
  setExpression: (newExpression: Expression | null) => void;
  includeExpressionOption: IncludeExpressionOptionFunction;
  shouldStack: boolean;
  allowEmpty?: boolean;
  focusOnMount?: boolean;
  selected?: Expression | null;
  optionsButton?: React.ReactNode;
  disablePanelOnSelect?: boolean;
}): React.ReactElement {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isExpressionSelectabilityEnabled =
    useHypertune().expressionsSelectability({ fallback: false });

  const expressionGroups = getExpressionOptionGroups(
    context.commitContext.schema,
    variables,
    valueTypeConstraint
  ).map((group) => ({
    ...group,
    options: group.options.filter((expressionOption) =>
      includeExpressionOption({
        expressionOption,
        expressionOptionParent: parentExpression,
      })
    ),
  }));

  const expressionMap = Object.fromEntries(
    expressionGroups
      .flatMap((group) =>
        group.options.map((expression) => [expression.id, expression])
      )
      .concat(selected ? [[selected.id, selected]] : [])
  );

  const groups: LabeledOptionGroup<string>[] = expressionGroups.map(
    (group) => ({
      label: group.label,
      options: group.options.map((expression) => ({
        value: expression.id,
        label: getExpressionOptionLabel(
          context.commitContext.schema,
          variables,
          expression
        ),
        icon: getExpressionOptionIcon(expression),
        showIconWhenSelected: true,
      })),
    })
  );

  const readOnly = isReadOnly(context);
  const noun =
    groups.length === 1 &&
    groups[0].label === variableExpressionOptionsGroupLabel
      ? "variable"
      : "expression";

  // This should never happen (that the user is able to expose an input where
  // they can't edit), and means the user is stuck. Ideally we'd send some alert
  // somewhere if this does happen. However, it's preferable to catch this case
  // to prevent permission leaks in case users do find odd ways to get here.
  if (readOnly && !selected) {
    if (context.expressionIdToIntent) {
      // Hack to allow for missing historical fields.
      return <div style={{ padding: normal }}>None</div>;
    }
    return (
      <div style={{ padding: normal }}>
        Error: Missing permission to select {noun}. Refresh the page to reset
        your changes.
      </div>
    );
  }

  const value = selected
    ? (groups
        .flatMap((group) => group.options)
        .find((option) =>
          expressionsAreEqual(expressionMap[option.value], selected)
        ) ?? {
        value: selected.id,
        label: getExpressionOptionLabel(
          context.commitContext.schema,
          variables,
          selected
        ),
        icon: getExpressionOptionIcon(selected),
      })
    : null;
  const isVariable =
    !!selected &&
    (selected.type === "ApplicationExpression" ||
      selected.type === "FunctionExpression" ||
      selected.type === "VariableExpression");

  const variableGroupIndex = context.variableGroupIndex ?? 0;
  const variable =
    selected?.type === "VariableExpression"
      ? variables[selected.variableId]
      : null;
  const variableValuePath = variable && variable?.path ? variable?.path : null;

  const showNavigateArrow =
    !!context.setSelectedFieldPath && !!variableValuePath;

  const intent = allowEmpty || selected ? "neutral" : "danger";

  return (
    <div
      className={`${getVariableGroupIndexClassName(variableGroupIndex)} insertExpressionControl group relative`}
    >
      <TopBarDropdown<string>
        readOnly={readOnly}
        dropdownStyle={{
          minWidth: 0,
          scrollToPosition: "center",
          buttonClassName: twMerge(
            "border font-medium rounded-lg h-[30px] pl-[6px] pr-2",
            intent === "neutral"
              ? variable
                ? "bg-white hover:border-base-pink group-hover:border-base-pink hover:bg-base-pink/5 focus:border-base-pink focus:shadow-variable-inputs-sm"
                : "bg-white hover:border-intent-primary group-hover:border-intent-primary hover:bg-intent-primary/5 focus:border-intent-primary focus:shadow-inputs-sm"
              : "hover:bg-intent-danger/10",
            selected
              ? isVariable
                ? "text-base-pink border-base-pink/15"
                : "text-type-icon-blue border-type-icon-blue/15"
              : "",
            optionsButton || showNavigateArrow ? "pr-8" : "",
            shouldStack ? "rounded-t-none" : ""
          ),
          openButtonClassName:
            intent === "neutral"
              ? variable
                ? "border-base-pink shadow-variable-inputs-sm"
                : "border-intent-primary shadow-inputs-sm"
              : "",
          optionClassName: "font-medium",
          panelClassName: "pt-1 data-top:pb-1",
          caretClassName: optionsButton || showNavigateArrow ? "mr-7" : "",
          caret: selected ? null : undefined,
        }}
        focusButtonOnMount={focusOnMount && !disablePanelOnSelect}
        intent={intent}
        options={
          groups.length === 1
            ? { type: "options", options: groups[0].options }
            : { type: "groups", groups }
        }
        value={value}
        placeholder={`Select ${noun}...`}
        // noOptionsMessage={`No ${noun} options`}
        onChange={(option) => {
          if (!option) {
            return;
          }
          const expression = expressionMap[option.value];
          if (!expression) {
            return;
          }
          if (selected && expressionsAreEqual(expression, selected)) {
            return;
          }

          setSelectedExpressionId(context, expression.id, expression);
          setExpression(expression);
        }}
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
      />
      {showNavigateArrow ? (
        <GoToArrowButton
          onClick={() => context.setSelectedFieldPath?.(variableValuePath)}
          type={variable ? "variable" : "primary"}
        />
      ) : optionsButton ? (
        <div
          style={{
            position: "absolute",
            top: optionsButtonTop,
            right: optionsButtonRight,
          }}
        >
          {optionsButton}
        </div>
      ) : null}
      {variable && variable.value && !dropdownOpen && (
        <VariableValuePreview
          groupIndex={variableGroupIndex}
          variableValue={
            <ExpressionControl
              context={{
                ...context,
                readOnly: true,
                hideOptions: true,
                variableGroupIndex: variableGroupIndex + 1,
                setSelectedFieldPath: undefined,
              }}
              variables={variables}
              setVariableName={{}}
              valueTypeConstraint={{ type: "AnyValueTypeConstraint" }}
              expression={variable.value}
              setExpression={() => {
                // noop
              }}
              lift={() => {
                // noop
              }}
              parentExpression={null}
              includeExpressionOption={() => false}
              disablePanelOnSelect={isExpressionSelectabilityEnabled}
            />
          }
        />
      )}
    </div>
  );
}
