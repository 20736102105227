import { ReactElement, useState } from "react";
import { NotePencil, ShieldCheck } from "@phosphor-icons/react";

import {
  DiscreteDimension,
  Expression,
  Split,
  SplitMap,
} from "@hypertune/sdk/src/shared";
import {
  deleteSplitDiscreteDimensionArm,
  removeSplitArmFromExpression,
} from "@hypertune/shared-internal";
import {
  Description,
  DetailContainer,
  DetailsContainer,
} from "../../../../components/Details";

import DeleteButton from "../../../../components/buttons/DeleteButton";
import { useHypertune } from "../../../../generated/hypertune.react";
import { useAppDispatch } from "../../../../app/hooks";
import { setDraftCommitSplitsAndExpression } from "../../projectSlice";
import ModalWithContent from "../../../../components/ModalWithContent";
import Button from "../../../../components/buttons/Button";

export default function SelectedArmDetails({
  readOnly,
  splits,
  split,
  expression,
  selectedArmId,
  selectedDimension,
  setSelectedArmId,
  setSplit,
}: {
  readOnly: boolean;
  splits: SplitMap;
  split: Split;
  expression: Expression;
  selectedArmId: string;
  selectedDimension: DiscreteDimension;
  setSelectedArmId: (newSelectedArmId: string) => void;
  setSplit: (newSelectedSplit: Split) => void;
}): ReactElement {
  const [armToDelete, setArmToDelete] = useState<{
    dimension: DiscreteDimension;
    armId: string;
  } | null>(null);

  const dispatch = useAppDispatch();

  const content = useHypertune().content().splits();

  return (
    <DetailsContainer>
      <Description
        readOnly={readOnly}
        text={
          (split.dimensions[selectedDimension.id] as DiscreteDimension).arms[
            selectedArmId
          ].description || ""
        }
        setText={(newDescription) =>
          setSplit({
            ...split,
            dimensions: {
              ...split.dimensions,
              [selectedDimension.id]: {
                ...selectedDimension,
                arms: {
                  ...(selectedDimension as DiscreteDimension).arms,
                  [selectedArmId]: {
                    ...(selectedDimension as DiscreteDimension).arms[
                      selectedArmId
                    ],
                    description: newDescription,
                  },
                },
              },
            },
          })
        }
        onClose={() => setSelectedArmId("")}
      />
      {!readOnly && (
        <DetailContainer icon={<NotePencil />} title="Actions">
          <div className="flex flex-row flex-wrap gap-2">
            {Object.keys(selectedDimension.arms).length > 1 && (
              <DeleteButton
                text="Delete"
                weight="elevated"
                onClick={() => {
                  setArmToDelete({
                    dimension: selectedDimension,
                    armId: selectedArmId,
                  });
                }}
              />
            )}
            <Button
              icon={<ShieldCheck weight="regular" />}
              weight="elevated"
              text={
                selectedDimension?.controlArmId === selectedArmId
                  ? "Control"
                  : "Mark as control"
              }
              disabled={selectedArmId === selectedDimension?.controlArmId}
              onClick={() => {
                const updatedSplit = {
                  ...split,
                  dimensions: Object.fromEntries(
                    Object.entries(split.dimensions).map(
                      ([dimensionId, dimension]) => [
                        dimensionId,
                        dimension === selectedDimension
                          ? {
                              ...dimension,
                              controlArmId: selectedArmId,
                            }
                          : dimension,
                      ]
                    )
                  ),
                };
                setSplit(updatedSplit);
              }}
            />
          </div>
        </DetailContainer>
      )}

      {armToDelete && split && (
        <ModalWithContent
          content={content.deleteArmModal().get()}
          onSave={() => {
            const { dimension, armId } = armToDelete;
            const updatedDimension =
              dimension.type === "discrete"
                ? {
                    ...dimension,
                    controlArmId:
                      dimension.controlArmId === armId
                        ? Object.keys(dimension.arms).filter(
                            (_armId) => _armId !== armId
                          )[0]
                        : dimension.controlArmId,
                  }
                : dimension;
            dispatch(
              setDraftCommitSplitsAndExpression({
                splits: deleteSplitDiscreteDimensionArm(
                  splits,
                  split,
                  updatedDimension,
                  armId
                ),
                expression: removeSplitArmFromExpression(
                  expression,
                  split.id,
                  dimension.id,
                  armId
                ),
              })
            );
            setArmToDelete(null);
            setSelectedArmId("");
          }}
          onClose={() => setArmToDelete(null)}
        />
      )}
    </DetailsContainer>
  );
}
