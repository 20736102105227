import { Trash } from "@phosphor-icons/react";

import Button, { Size, Weight, sizeToIconSize } from "./Button";
import { intentDangerHex } from "../../lib/constants";

export default function DeleteButton({
  title,
  size = "default",
  weight = "minimal",
  text,
  disabled,
  loading,
  hideIcon,
  onClick,
  className,
}: {
  title?: string;
  size?: Size;
  weight?: Weight;
  text?: string;
  disabled?: boolean;
  loading?: boolean;
  hideIcon?: boolean;
  onClick: () => void;
  className?: string;
}): React.ReactElement {
  return (
    <Button
      intent="danger"
      title={title}
      weight={weight}
      size={size}
      text={text}
      disabled={disabled}
      loading={loading}
      icon={
        !hideIcon ? (
          <Trash
            weight="regular"
            color={intentDangerHex}
            size={sizeToIconSize(size)}
          />
        ) : undefined
      }
      onClick={onClick}
      className={className}
    />
  );
}
