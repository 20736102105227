import {
  EventTypeMap,
  Expression,
  Schema,
  SplitMap,
  uniqueId,
} from "@hypertune/sdk/src/shared";
import {
  AnalyticsSchema,
  cloneObject,
  collectSplitAndEventTypeIds,
  copyImplementation,
  formatTypeSchemaName,
} from "@hypertune/shared-internal";
import getListFieldLabelExpression from "./getListFieldLabelExpression";

export type NullListExpression = { id: string; type: "NullListExpression" };

export default function getListExpressionItemsAndSetItemsFunction(
  expression: Expression | null
): {
  items: (Expression | NullListExpression)[] | null;
  setItems: (items: (Expression | NullListExpression)[]) => Expression | null;
} {
  switch (expression?.type) {
    case "ListExpression": {
      return {
        items: expression.items.map((item) =>
          item === null ? { id: uniqueId(), type: "NullListExpression" } : item
        ),
        setItems: (newItems) => {
          return {
            ...expression,
            items: newItems.map((item) =>
              item.type === "NullListExpression" ? null : item
            ),
          };
        },
      };
    }
    case "FunctionExpression": {
      const expressionItems = getListExpressionItemsAndSetItemsFunction(
        expression.body
      );
      return {
        items: expressionItems.items,
        setItems: (newItems) => {
          return {
            ...expression,
            body: expressionItems.setItems(newItems),
          };
        },
      };
    }
    case "ApplicationExpression": {
      const expressionItems = getListExpressionItemsAndSetItemsFunction(
        expression.function
      );
      return {
        items: expressionItems.items,
        setItems: (newItems) => {
          return {
            ...expression,
            function: expressionItems.setItems(newItems),
          };
        },
      };
    }
    default: {
      return {
        items: null,
        setItems: () => expression,
      };
    }
  }
}

const copyOfPrefix = "Copy of ";
const copyOfPrefixFormatted = formatTypeSchemaName(copyOfPrefix);

export function duplicateItemInListExpression(
  schema: Schema,
  expressionItems: (Expression | NullListExpression)[],
  expression: Expression,
  analyticsSchema: Omit<AnalyticsSchema, "features">
): {
  newExpressionItems: (Expression | NullListExpression)[];
  newSplits: SplitMap;
  newEventTypes: EventTypeMap;
  newSchema: Schema;
} {
  const index = expressionItems.findIndex((item) => item?.id === expression.id);
  const { eventTypeIds, splitIds } = collectSplitAndEventTypeIds(expression);
  const {
    expression: newExpression,
    splits: newSplits,
    eventTypes: rawEventTypes,
  } = copyImplementation(
    {
      expression,
      features: {},
      splits: Object.fromEntries(
        Object.entries(analyticsSchema.splits).filter(([splitId]) =>
          splitIds.has(splitId)
        )
      ),
      eventTypes: Object.fromEntries(
        Object.entries(analyticsSchema.eventTypes).filter(([eventTypeId]) =>
          eventTypeIds.has(eventTypeId)
        )
      ),
    },
    {
      namePrefix: copyOfPrefix,
      skipVariableCopy: true,
    }
  );
  const labelExpression = getListFieldLabelExpression(newExpression);
  if (labelExpression) {
    labelExpression.value = `${copyOfPrefix}${labelExpression.value}`;
  }
  const newEventTypes = Object.fromEntries(
    Object.entries(rawEventTypes).map(([id, eventType]) => [
      id,
      { ...eventType, name: formatTypeSchemaName(eventType.name) },
    ])
  );

  return {
    newExpressionItems: [
      ...expressionItems.slice(0, index + 1),
      newExpression,
      ...expressionItems.slice(index + 1),
    ],
    newSplits,
    newEventTypes,
    newSchema: Object.values(newEventTypes).reduce(
      (currentSchema, eventType) =>
        cloneObject(
          currentSchema,
          eventType.name.replace(copyOfPrefixFormatted, ""),
          eventType.name,
          "event"
        ),
      schema
    ),
  };
}
