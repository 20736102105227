import React, { ReactElement, useMemo, useState } from "react";
import {
  Arm,
  Dimension,
  DiscreteDimension,
  Split,
  SplitType,
} from "@hypertune/sdk/src/shared";
import { Plus } from "@phosphor-icons/react";
import {
  createArm,
  createDiscreteDimension,
} from "@hypertune/shared-internal/src/expression/split";
import getArmAllocationsSum from "@hypertune/shared-internal/src/expression/getArmAllocationsSum";

import Button from "../../../../components/buttons/Button";
import TypeIcon from "../../../../components/icons/TypeIcon";
import MutableText from "../../../../components/input/MutableText";
import FloatInput from "../../../../components/input/FloatInput";
import twMerge from "../../../../lib/twMerge";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import Label from "../../../../components/Label";
import { showSchemaNameError } from "../../schema/typeEditor/SchemaNameError";
import NameError from "./NameError";
import ModalWithContent from "../../../../components/ModalWithContent";
import { useHypertune } from "../../../../generated/hypertune.react";
import { boldFontWeight } from "../../../../lib/constants";
import Tag from "../../../../components/Tag";
import Card from "../../../../components/Card";
import SortableList from "../../../../components/SortableList";

export default function SelectedSplitEditor({
  split,
  setSplit,
  readOnly,
  setSelectedArmId,
  selectedArmId,
}: {
  split: Split;
  setSplit: (newSplit: Split) => void;
  readOnly: boolean;
  setSelectedArmId: (newSelectedArmId: string) => void;
  selectedArmId: string;
}): ReactElement {
  const content = useHypertune().content().splits();
  const [dimensionIdToDelete, setDimensionIdToDelete] = useState<string | null>(
    null
  );

  const sortedDimensions = Object.values(split.dimensions).sort(
    (a, b) => a.index - b.index
  );

  function addDimension(): void {
    const newDimension = createDiscreteDimension(
      split.id,
      Object.keys(split.dimensions).length
    );

    setSplit({
      ...split,
      dimensions: {
        ...split.dimensions,
        [newDimension.id]: {
          ...newDimension,
        },
      },
    });
  }

  function setDimension(updatedDimension: Dimension): void {
    setSplit({
      ...split,
      dimensions: {
        ...split.dimensions,
        [updatedDimension.id]: updatedDimension,
      },
    });
  }

  function addArm(dimensionId: string): void {
    const currentDimension = split.dimensions[dimensionId];

    if (currentDimension.type !== "discrete") {
      return;
    }

    const armCount = Object.values(currentDimension.arms).length;
    const newArm = createArm(dimensionId, armCount, `Arm ${armCount + 1}`);

    setSplit({
      ...split,
      dimensions: {
        ...split.dimensions,
        [dimensionId]: {
          ...currentDimension,
          arms: {
            ...currentDimension.arms,
            [newArm.id]: newArm,
          },
        },
      },
    });

    setSelectedArmId(newArm.id);
  }

  function setArm(updatedArm: Arm): void {
    const dimension = split.dimensions[updatedArm.dimensionId];

    if (dimension.type !== "discrete") {
      return;
    }

    setSplit({
      ...split,
      dimensions: {
        ...split.dimensions,
        [updatedArm.dimensionId]: {
          ...dimension,
          arms: {
            ...dimension.arms,
            [updatedArm.id]: updatedArm,
          },
        },
      },
    });
  }

  const singleDiscreteDimension =
    sortedDimensions.length === 1 && sortedDimensions[0].type === "discrete"
      ? sortedDimensions[0]
      : null;

  return (
    <div className="grid gap-3">
      {singleDiscreteDimension ? (
        <>
          <Label className="whitespace-nowrap" type="title1">
            Arms
          </Label>
          <div className="grid grid-cols-1 gap-3 pb-0">
            <DimensionContent
              readOnly={readOnly}
              dimension={singleDiscreteDimension}
              split={split}
              setSplit={setSplit}
              setArm={setArm}
              selectedArmId={selectedArmId}
              setSelectedArmId={setSelectedArmId}
            />
          </div>
          <AddArmButton
            readOnly={readOnly}
            dimensionId={singleDiscreteDimension.id}
            addArm={addArm}
          />
        </>
      ) : null}
      {sortedDimensions.length > 1 ? (
        <>
          <Label className="whitespace-nowrap" type="title1">
            Dimensions
          </Label>
          <div className="flex flex-col gap-3">
            <SortableList
              disabled={readOnly || sortedDimensions.length === 1}
              list={sortedDimensions}
              setList={(reorderedDimensions) =>
                setSplit({
                  ...split,
                  dimensions: Object.fromEntries(
                    reorderedDimensions.map((dimension, index) => [
                      dimension.id,
                      { ...dimension, index },
                    ])
                  ),
                })
              }
              renderItemComponent={({ id: dimensionId, dragHandle, index }) => {
                const dimension = split.dimensions[dimensionId];
                if (dimension.type !== "discrete") {
                  return null;
                }

                return (
                  <>
                    <div className={!readOnly ? "mb-3" : ""}>
                      <DimensionHeaderCard
                        readOnly={readOnly}
                        index={index}
                        dimension={dimension}
                        setDimension={setDimension}
                        setDimensionIdToDelete={setDimensionIdToDelete}
                        split={split}
                        showDelete={sortedDimensions.length > 1}
                        dragHandle={dragHandle}
                      />
                      <DimensionContent
                        readOnly={readOnly}
                        dimension={dimension}
                        split={split}
                        setSplit={setSplit}
                        setArm={setArm}
                        selectedArmId={selectedArmId}
                        setSelectedArmId={setSelectedArmId}
                        cardsAreListItems
                      />
                    </div>
                    <AddArmButton
                      readOnly={readOnly}
                      dimensionId={dimension.id}
                      addArm={addArm}
                    />
                  </>
                );
              }}
            />
          </div>
        </>
      ) : null}
      {!readOnly && (
        <Button
          className="justify-self-start"
          disabled={readOnly}
          intent="neutral"
          weight="outlined"
          size="large"
          icon={<Plus weight="regular" />}
          text="Add dimension"
          onClick={addDimension}
        />
      )}
      {dimensionIdToDelete && (
        <ModalWithContent
          content={content.deleteDimensionModal().get()}
          onSave={() => {
            const { [dimensionIdToDelete]: _, ...newDimensions } =
              split.dimensions;

            setSplit({
              ...split,
              dimensions: newDimensions,
            });
            setDimensionIdToDelete(null);
          }}
          onClose={() => setDimensionIdToDelete(null)}
        />
      )}
    </div>
  );
}

function DimensionContent({
  readOnly,
  dimension,
  split,
  setSplit,
  setArm,
  selectedArmId,
  setSelectedArmId,
  cardsAreListItems,
}: {
  readOnly: boolean;
  dimension: DiscreteDimension;
  split: Split;
  setSplit: (newSplit: Split) => void;
  setArm: (newArm: Arm) => void;
  selectedArmId: string;
  setSelectedArmId: (newSelectedArmId: string) => void;
  cardsAreListItems?: boolean;
}): React.ReactElement {
  const sortedArms = Object.values(dimension.arms).sort(
    (a, b) => a.index - b.index
  );
  return (
    <>
      <SortableList
        disabled={readOnly || sortedArms.length === 1}
        list={sortedArms}
        setList={(reorderedArms) =>
          setSplit({
            ...split,
            dimensions: {
              ...split.dimensions,
              [dimension.id]: {
                ...dimension,
                arms: Object.fromEntries(
                  reorderedArms.map((arm, index) => [arm.id, { ...arm, index }])
                ),
              },
            },
          })
        }
        renderItemComponent={({ id: armId, dragHandle }) => (
          <ArmCard
            split={split}
            dimension={dimension}
            arm={dimension.arms[armId]}
            setArm={setArm}
            readOnly={readOnly}
            selectedArmId={selectedArmId}
            setSelectedArmId={setSelectedArmId}
            dragHandle={dragHandle}
            isListItem={cardsAreListItems}
          />
        )}
      />
      <UnallocatedCard isListItem={cardsAreListItems} dimension={dimension} />
    </>
  );
}

function DimensionHeaderCard({
  readOnly,
  index,
  dimension,
  setDimension,
  setDimensionIdToDelete,
  split,
  showDelete,
  dragHandle,
}: {
  readOnly: boolean;
  index: number;
  dimension: DiscreteDimension;
  setDimension: (newDimension: Dimension) => void;
  setDimensionIdToDelete: (dimensionId: string) => void;
  split: Split;
  showDelete?: boolean;
  dragHandle?: React.ReactNode;
}): React.ReactElement {
  return (
    <Card
      isListItem
      layout="horizontal-with-icon"
      className={`flex justify-between py-2 pl-[10px] ${index > 0 ? "mt-3" : ""}`}
    >
      <div className="flex flex-row items-center gap-[5px]">
        {dragHandle}
        <MutableText
          className="font-semibold"
          text={dimension.name}
          setText={(newValue: string) =>
            Promise.resolve(setDimension({ ...dimension, name: newValue }))
          }
          showPencil={false}
          hasError={(newName) =>
            NameError({
              noun: "Dimension",
              idMap: split.dimensions,
              currentName: dimension.name,
              newName,
            })
          }
          showError={showSchemaNameError}
          readOnly={readOnly}
        />
      </div>
      {!readOnly && showDelete ? (
        <DeleteButton onClick={() => setDimensionIdToDelete(dimension.id)} />
      ) : null}
    </Card>
  );
}

function UnallocatedCard({
  dimension,
  isListItem,
}: {
  dimension: DiscreteDimension;
  isListItem?: boolean;
}): React.ReactElement {
  return (
    <Card
      className="border-grey-border flex min-w-[252px] bg-base-grey-2-light py-2 pl-6 pr-[16px]"
      layout="horizontal-with-icon"
      isListItem={isListItem}
    >
      <Label
        type="title4"
        className="flex w-full justify-between text-base-grey-1-dark"
      >
        <span>Unallocated </span>
        <div>
          <span className="pr-[14px]">
            {(Math.max(1 - getArmAllocationsSum(dimension), 0) * 100).toFixed(
              2
            )}
          </span>
          <span>%</span>
        </div>
      </Label>
    </Card>
  );
}

function AddArmButton({
  readOnly,
  dimensionId,
  addArm,
}: {
  readOnly: boolean;
  dimensionId: string;
  addArm: (addToDimensionId: string) => void;
}): React.ReactElement | null {
  if (readOnly) {
    return null;
  }
  return (
    <Button
      className="justify-self-start"
      disabled={readOnly}
      intent="neutral"
      weight="outlined"
      size="large"
      icon={<Plus weight="regular" />}
      text="Add arm"
      onClick={() => {
        addArm(dimensionId);
      }}
    />
  );
}

function ArmCard({
  split,
  dimension,
  arm,
  setArm,
  readOnly,
  selectedArmId,
  setSelectedArmId,
  dragHandle,
  isListItem,
}: {
  split: Split;
  dimension: DiscreteDimension;
  arm: Arm;
  setArm: (newArm: Arm) => void;
  readOnly: boolean;
  selectedArmId: string;
  setSelectedArmId: (newArmsId: string) => void;
  dragHandle?: React.ReactNode;
  isListItem?: boolean;
}): React.ReactElement {
  return (
    <Card
      className="h-[74px] min-w-[252px] items-center gap-3 pl-[10px]"
      layout="horizontal-with-icon"
      isListItem={isListItem}
      isSelected={selectedArmId === arm.id}
      onMouseDown={(event) => {
        event.stopPropagation();
        setSelectedArmId(arm.id);
      }}
    >
      <div className="flex flex-row items-center gap-2">
        {dragHandle}
        <TypeIcon type="arm" size="large" />
      </div>
      <ArmControl
        splitType={split.type}
        arm={arm}
        setArm={setArm}
        readOnly={readOnly}
        dimension={dimension}
      />
    </Card>
  );
}

function ArmControl({
  className,
  splitType,
  arm,
  setArm,
  readOnly,
  dimension,
}: {
  className?: string;
  splitType: SplitType;
  arm: Arm;
  setArm: (newArm: Arm) => void;
  readOnly: boolean;
  dimension: DiscreteDimension;
}): React.ReactElement {
  const dimensionAllocation = useMemo(
    () => getArmAllocationsSum(dimension),
    [dimension]
  );
  const hasError = arm.allocation > 0 && dimensionAllocation > 1;

  return (
    <div
      className={twMerge(
        "grid auto-cols-auto grid-flow-col grid-cols-[1fr] items-center gap-2",
        className
      )}
    >
      <div className="-ml-[2px] flex h-full max-w-full flex-shrink flex-row items-center overflow-hidden px-[2px]">
        <MutableText
          text={arm.name}
          readOnly={readOnly}
          setText={(newValue: string) =>
            Promise.resolve(setArm({ ...arm, name: newValue }))
          }
          showPencil={false}
          hasError={(newName) =>
            NameError({
              noun: "Arm",
              idMap: dimension.arms,
              currentName: arm.name,
              newName,
            })
          }
          showError={showSchemaNameError}
          style={{
            lineHeight: "16px",
            fontWeight: boldFontWeight,
            maxWidth: "100%",
          }}
          minWidth={0}
          className="max-w-full overflow-x-clip text-ellipsis whitespace-nowrap"
        />
      </div>
      {splitType === "test" ? (
        <>
          {dimension.controlArmId === arm.id && (
            <Tag text="Control" intent="success" />
          )}
          <FloatInput
            style={{ width: 50 }}
            inputClassName="w-[34px] text-right"
            initialValue={arm.allocation * 100}
            min={0}
            max={100}
            readOnly={readOnly}
            onChange={(newValue: number) =>
              setArm({
                ...arm,
                allocation: newValue / 100,
              })
            }
            intent={hasError ? "danger" : undefined}
          />
          <div>%</div>
        </>
      ) : null}
    </div>
  );
}
