import {
  SchemaCheck,
  checkSchemaName,
  reservedTypeNames,
} from "@hypertune/shared-internal";
import { Schema } from "@hypertune/sdk/src/shared";
import React from "react";
import StructuredErrorMessage from "../../../../components/StructuredErrorMessage";

export type SchemaCheckOrError = SchemaCheck | string | null;

export default function SchemaNameError({
  schemaCheckOrError,
}: {
  schemaCheckOrError: SchemaCheckOrError;
}): React.ReactElement | null {
  if (!schemaCheckOrError) {
    return null;
  }
  if (typeof schemaCheckOrError === "string") {
    return <>{schemaCheckOrError} </>;
  }
  if (schemaCheckOrError.valid) {
    return null;
  }
  return (
    <StructuredErrorMessage
      checks={[
        {
          ok: schemaCheckOrError.startsWithLetter,
          message: "Starts with letter",
        },
        {
          ok: schemaCheckOrError.noSpecialCharacters,
          message: "Only letters and numbers",
        },
      ]}
    />
  );
}

export function showSchemaNameError(
  schemaCheckOrError: SchemaCheckOrError
): React.ReactElement | null {
  return <SchemaNameError schemaCheckOrError={schemaCheckOrError} />;
}

export function typeNameError(
  schema: Schema,
  typeSchemaName: string
): SchemaCheckOrError {
  const schemaCheck = checkSchemaName(typeSchemaName);

  const typeNameExists =
    !!schema.objects[typeSchemaName] ||
    !!schema.enums[typeSchemaName] ||
    !!schema.unions[typeSchemaName];

  if (typeSchemaName && !schemaCheck.valid) {
    return schemaCheck;
  }
  if (typeNameExists) {
    return "A type with this name already exists";
  }
  if (reservedTypeNames.includes(typeSchemaName)) {
    return "This type name is reserved. Please use a different one.";
  }

  return null;
}

export function objectFieldNameError(
  schema: Schema,
  entityName: "field" | "flag" | "test",
  objectTypeName: string,
  fieldSchemaName: string
): SchemaCheckOrError {
  const schemaCheck = checkSchemaName(fieldSchemaName);

  if (fieldSchemaName && !schemaCheck.valid) {
    return schemaCheck;
  }
  if (schema.objects[objectTypeName]?.fields[fieldSchemaName]) {
    return `A ${entityName} with this name already exists`;
  }
  return null;
}

const reservedNames = ["true", "false", "null"];

export function enumValueNameError(
  schema: Schema,
  enumTypeName: string,
  schemaValueName: string
): SchemaCheckOrError {
  const schemaCheck = checkSchemaName(schemaValueName);

  if (schemaValueName && !schemaCheck.valid) {
    return schemaCheck;
  }
  if (schema.enums[enumTypeName].values[schemaValueName]) {
    return "Value already exists";
  }
  if (reservedNames.includes(schemaValueName)) {
    return "This value name is reserved.";
  }
  return null;
}

export function operationConfirmText(
  operationName: string,
  typeName: string
): string {
  return `Are you sure you want to ${operationName} this ${typeName}? 
          This can cause your code to rely on fallbacks and 
          it will change the generated code as well what can 
          in turn break your build.`;
}
